import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Row, Col, Alert } from "antd";
import './index.scss';
import RedirectToPage from "../../components/RedirectToPage";
import { useTranslation } from "react-i18next";
import { login } from "../../store/reducers/auth";
import { requestFirebaseNotificationPermission } from "../../firebaseInit";
import { useEffect } from "react";

const LoginPage = () => {
  const { t } = useTranslation();
  // console.log(isAuthSelector,'isAuthSelector');
  // const isLogin = useSelector(isAuthSelector);
  const dispatch = useDispatch();

  const { user, error } = useSelector(state => state.auth);
  useEffect( async () => {
    
  }, []);
  const onFinish = async (values) => {
    const deviceToken = await requestFirebaseNotificationPermission();
    values['device_token'] = deviceToken;
    dispatch(login(values));
  };

  if (user) {
    return <RedirectToPage to={'/'} />;
  }

  return (
    <div className="login-page">
      <Row justify="space-around" align="middle" className="container">

        <Col >
          <div className="logo">
            <span>SkyConnect</span>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            className="login-form"
          >
            <h2 className="login-form__title"> {t('login-page.title')} </h2>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: t('login-page.error.phone-required')
                },
              ]}
            >
              <Input placeholder={t('login-page.username')} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('login-page.error.password-required')
                },
              ]}
            >
              <Input
                type="password"
                placeholder={t('login-page.password')}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form__btn">
                {t('login-page.button')}
              </Button>
            </Form.Item>
            {error && <Alert message={t('login-page.' + error)} type="error" />}
          </Form>
        </Col>
      </Row>


    </div>
  );

};

export default LoginPage;
