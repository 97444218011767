import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CREATE_NEWS_URL,GET_API_LISTAGS } from "../../constants/endpoint";
import { callAuthorizationApi } from "../../apis/axiosClient";

const initialState = {
  isLoading: false,
  status: "idle",
  data: [],
  error: false,
  success: false,
  dataListTags:[],
  total: 0,
  per_page: 0,
};

export const addNewNews = createAsyncThunk("news/addNewNews", async (dataPost, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(CREATE_NEWS_URL, "POST", dataPost);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const getListTags = createAsyncThunk("news/getListTags", async (dataPost, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(GET_API_LISTAGS, "POST", dataPost);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});
const slice = createSlice({
  name: "news",
  initialState,
  reducers: {
    clearStatusNews:(state)=>{
      state.success=false;
  }
  },
  extraReducers: {
    [addNewNews.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
      state.success = false;
    },
    [addNewNews.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.data.push(action.payload.data);
    },
    [addNewNews.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
      state.success = false;
    },
    [getListTags.pending]: (state) => {
      state.isLoading = true;
    },
    [getListTags.fulfilled]: (state, action) => {
      state.isLoading = true;
      state.dataListTags=action.payload.data.data;
      state.total = action.payload.data.total;
      state.per_page = action.payload.data.per_page;
    },
    [getListTags.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;
const {clearStatusNews} = slice.actions;
export const clearStatus = () => async dispatch => {
  return dispatch(clearStatusNews());
};