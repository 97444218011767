import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Radio, Row, DatePicker, Space, Select, Modal, Tag, Table } from "antd";
import { useTranslation } from "react-i18next";
import SerpPreview from "react-serp-preview";
import { URL_REGEX } from "../../constants";
import TextEditor from "../editor";
import { useSelector } from "react-redux";
import { ReactComponent as IconInfo } from "../../assets/images/info_outline.svg";
import "./create-new.scss";
import CustomTable from '../CustomTable';
import CustomPagination from '../CustomPagination';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { hash } from 'immutable';
const { TextArea } = Input;
const CreateNew = ({
  onChangeTextEditor,
  isLoading,
  userType,
  setUserType,
  children,
  isNotification,
  titlePreview,
  setTitlePreview,
  summaryPreview,
  setSummaryPreview,
  linkNews,
  setLinkNews,
  source,
  setSource,
  externalLink,
  setExternalLink,
  showModalTags,
  showModal,
  handleCancelTags,
  categories,
  dateDisplay,
  handlCheckDate,
  setDateDisplay,
  onCurrentPage,
  current,
  form
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onChangeUrl = () => {
    history.push('/list-news');
  };
  const { listtags } = useSelector(state => state.listnews);
  const { dataListTags, total, per_page } = useSelector((state) => state.news);
  const [date, setDate] = useState(0);
  const handleDate = (e) => {
    if (e != null) {
      const date = new Date(e._d).getDate();
      setDate(date);
    }
  };
  
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
  return (
    <Row>
      <Col span={16}>
        <div className="create-news__left">
          <Form.Item
            label={t("create-news.title-news")}
            name="title"
            rules={[{ required: true, message: t("create-news.title-news-error") }]}
          >
            <Input
              placeholder={t("create-news.description-news")}
              onChange={(e) => {
                setTitlePreview && setTitlePreview(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("create-news.title-content")}
            name="content"
            rules={[{ required: true, message: t("create-news.content-error") }]}
          >
            <TextEditor
              placeholder={t("create-news.description-content")}
              onChangeTextEditor={onChangeTextEditor}
            />
          </Form.Item>

          {isNotification ? (
            <Form.Item
              label={
                <p className="mb-0">
                  <span>{t("notification.title-link")}&nbsp;&nbsp;</span>
                  <span className="color-secondary">{t("notification.heading-link")}</span>
                </p>
              }
              name="link"
              rules={[{ transform: (value) => value ? value.trim() : null, pattern: URL_REGEX, message: t("notification.error-link") },

              ]}
            >
              <TextArea placeholder={t("notification.description-link")} rows={1} />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label={
                  <p className="mb-0">
                    <span>{t("create-news.title-summary")}&nbsp;&nbsp;</span>
                    <span className="color-secondary">{t("create-news.heading-summary")}</span>
                  </p>
                }
                name="description"
                rules={[{ required: true, message: t("create-news.summary-error") }]}
              >
                <TextArea
                  onChange={(e) => {
                    setSummaryPreview && setSummaryPreview(e.target.value);
                  }}
                  placeholder={t("create-news.description-summary")}
                  rows={3}
                />
              </Form.Item>
              <div className="news-container">
                <div className="news-container__infomation">
                  <Form.Item
                    label={t("create-news.information-resource")}
                    name="external_link"

                    rules={[{ required: true, message: t("create-news.information-resource-error") },
                    ]}
                  >
                    <Input
                      placeholder={t("create-news.description-news")}
                      onChange={(e) => {
                        source && setSource(e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="news-container__links">
                  <Form.Item
                    name="source"
                    label={t("create-news.link-news")}
                    rules={[{
                      transform: (value) => value ? value.trim() : null,
                      pattern: URL_REGEX, message: t("employee.link-error")
                    },
                    {
                      required: true, message: t("create-news.link-news-error")
                    },

                    ]}
                  >
                    <Input
                      placeholder={t("create-news.description-news")}
                      onChange={(e) => {
                        externalLink && setExternalLink(e.target.value);
                        setLinkNews && setLinkNews(e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="create-news__box-preview">
                <div className="create-news__box-preview-title">
                  <p>{t("create-news.title-view")}</p>{" "}
                  <Button htmlType="button" type="link">
                    {t("create-news.edit-seo")}
                  </Button>
                </div>
                <SerpPreview title={titlePreview} metaDescription={summaryPreview} url={linkNews} />
              </div>
            </>
          )}

          <div className="create-news__btn-group">
            <Button htmlType="button" onClick={() => onChangeUrl()}>
              {t("create-news.btn-cancel")}
            </Button>
            <Button htmlType="submit" type="primary">
              {t("create-news.btn-save")}
            </Button>
          </div>
        </div>
      </Col>
      <Col span={8}>
        <div className="create-news__right">
          <div className="create-news__box-white">
            {isNotification ? (
              <Form.Item
                label={t("create-news.title-category")}
                name="notify_category_id"
                rules={[{ required: true, message: t("create-news.error-category") }]}
              >
                <Select placeholder={t("create-news.description-category")} options={categories} />
              </Form.Item>
            ) : (
              <Form.Item
                label={t("create-news.title-category")}
                name="news_category_id"
                rules={[{ required: true, message: t("create-news.error-category") }]}
              >
                <Select placeholder={t("create-news.description-category")} options={categories} />
              </Form.Item>
            )}
            {isNotification && (
              <Form.Item
                label={t("notification.title-object")}
                name="for_user_type"
                rules={[{ required: true, message: t("notification.error-object") }]}
              >
                <Select
                  placeholder={t("notification.description-object")}
                  options={[
                    { label: "Tất cả", value: "3" },
                    { label: "Nhà môi giới", value: "2" },
                    { label: "Người dùng", value: "1" },
                  ]}
                />
              </Form.Item>
            )}
            <Form.Item
              className="create-news-box-tag create-news-box-tag--custom"
              label={
                <div className="create-news-box-tag__title">
                  <p>
                    <span>{t("create-news.title-tag")}&nbsp;</span>
                    <IconInfo />
                  </p>
                  <Button type="link" htmlType="button" onClick={showModalTags}>
                    {t("create-news.view-tag")}
                  </Button>
                </div>
              }
              name="tags"
            >
              <Select
                mode="tags"
                placeholder={t("create-news.description-tag")}
                style={{ width: "100%" }}
                autoFocus={true}
              >
                {dataListTags?.length ? dataListTags.map(item => (
                  <Select.Option key={item.name}>{item.name}</Select.Option>
                )) : null};
              </Select>
            </Form.Item>
          </div>
          <div className="create-news__box-white create-news__box-status">
            <Form.Item
              label={t("create-news.title-status")}
              name="status"
              rules={[

                {
                  validator: () => (userType ? Promise.resolve() : Promise.reject(t("errorThumbnail"))),
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => { setUserType(e.target.value), setDateDisplay(false); }}
                value={userType}
                options={[
                  { label: t("create-news.show-web"), value: "1" },
                  { label: t("create-news.hide"), value: "2" },
                ]}
              />
              <Button type="link" htmlType="button" className="mb-0" onClick={() => handlCheckDate()} disabled={userType === "1" ? true : false}>
                {t("create-news.date-show")}
              </Button>
            </Form.Item>
            {dateDisplay && userType === '2' ? <Form.Item
              name="public_date"
              rules={[
                {
                  required: true, message: t('create-news.date-unique'),
                },
                { validator: () => (date && date <= new Date().getDate() ? Promise.reject(t("create-news.date-error")) : Promise.resolve()) }

              ]}
            >
              <DatePicker format={dateFormatList} onChange={(e) => handleDate(e)} placeholder={t('update-news.update-date')} />
            </Form.Item> : null}

          </div>
          <Modal centered title={t('Danh sách tags')}
            visible={showModal}
            onCancel={handleCancelTags}
            okButtonProps={{ style: { display: 'none' } }}
          >
            <div className="tags-container">
              <CustomTable data={dataListTags} rowselect={false}>
                <Table.Column
                  align='center'
                  title={t('news.column.number-id')}
                  dataIndex='key'
                  key="key"
                  className="table-list__row--title"
                  render={(text, record, index) => {
                    return (
                      <span>{index + 1}</span>
                    );

                  }}

                />
                <Table.Column
                  align='center'
                  title={t('news.column.name')}
                  className="table-list__row--title"
                  render={(e) => {
                    return (
                      <div className="image-title">
                        {e.name}
                      </div>
                    );
                  }}
                />
              </CustomTable>
              <CustomPagination total={total} current={current} perPage={per_page} pageSize={per_page || 10} onChange={(e) => onCurrentPage(e)}></CustomPagination>
            </div>
          </Modal>
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default CreateNew;
