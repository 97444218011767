import axios from "axios";
import queryString from "query-string";

import { getToken, getUserStorege } from "../helpers";
require("dotenv").config();
// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common.Accept = "application/json";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      localStorage.removeItem("user");
      window.location = "/login";
    } else {
      // Promise.reject((error.response && error.response.data) || "Something went wrong");
      throw error;
    }
  }
);

export const callApi = (
  endpoint,
  method = "GET",
  body = {},
  params = {},
  headers = { "Content-Type": "application/json" }
) => {
  return axios({
    method,
    url: `${endpoint}`,
    headers: { ...headers, Accept: "application/json" },
    data: body,
    params,
    paramsSerializer: (params) => queryString.stringify(params),
  });
};

export const callAuthorizationApi = (
  endpoint,
  method = "GET",
  body = {},
  params = {},
  headers = { "Content-Type": "application/json" }
) => {
  const user = getUserStorege();
  const token = user?.access_token;
  return callApi(endpoint, method, body, params, {
    ...headers,
    Authorization: `Bearer ${token}`,
  });
};
