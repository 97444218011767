import { Checkbox, Col, Form, Input, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import './employee.scss';
// eslint-disable-next-line no-useless-escape
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
const PHONE_VN_REGEX = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
import { checkPermissions } from '../../helpers/role';
import { Permission } from '../../helpers/permission';
const { TextArea } = Input;

const EmployeeModalCreate = ({ listRole, setAccessLimit, accessLimit, isEdit, checked, onChange }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={24}>
      <Col className="gutter-row" span={12}>
        <Form.Item name="name" className="gutter-row__item" label={<p className="gutter-row__item" >{t("employee.name")}</p>} rules={[{ required: true, message: t("employee.account-error") }]}>
          <Input placeholder={t("employee.name")} />
        </Form.Item>
      </Col>
      <Col className="gutter-row" span={12}>
        <Form.Item
          className="gutter-row__item"
          name="phone"
          label={<p className="gutter-row__item" >{t("employee.phone")}</p>}
          rules={[
            // { transform: (value) => value.trim() },
            { required: true, message: t("employee.phone-error-null") },
            { transform: (value) => value ? value.trim() : null, pattern: PHONE_VN_REGEX, message: t("employee.phone-error") },
            { max: 20, message: t("employee.name-max-error") },
          ]}
        >
          <Input placeholder={t("employee.phone")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="email" className="gutter-row__item"
          label={<p className="gutter-row__item">{t('employee.email')}</p>}
          rules={[{ transform: (value) => value ? value.trim() : null, type: "email", message: t("employee.email-error") },
          { required: true, message: t("employee.email-error-null") },
            // { max: 20, message: t("employee.email-max-error") },
          ]}>
          <Input placeholder={t("employee.email")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          className="gutter-row__item"
          name="facebook"
          label={<p className="gutter-row__item" >{t("employee.link")}</p>}
          rules={[{ transform: (value) => value ? value.trim() : null, pattern: URL_REGEX, message: t("employee.link-error") },
          { required: true, message: t("employee.link-error-null") },
          ]}
        >
          <Input placeholder={t("employee.link")} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="address"
          className="gutter-row__item"
          label={<p className="gutter-row__item" >{t("employee.address")}</p>}
          rules={[{ transform: (value) => value ? value.trim() : null, required: true, message: t("employee.address-error") },
          { max: 255, message: t("employee.address-max-error") },
          ]}
        >
          <Input placeholder={t("employee.address")} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={
            <p className="mb-0">
              <span>{t("employee.note")}</span>
            </p>
          }
          name="note"
        >
          <TextArea
            placeholder="ghi chú"
            rows={3}
          />
        </Form.Item>
      </Col>
      <Col span={24} className="employee-modal__role">
        <Form.Item
          name="roles"
          className="gutter-row__item--checkbox"
          label={
            <div className="employee-modal__role-label">
              <div>
                <p className="gutter-row__item">{t("employee.limit")}</p>
                <Radio.Group
                  onChange={() => setAccessLimit(!accessLimit)}
                  value={accessLimit ? "limit-access" : "full-access"}
                  options={[
                    { label: <p className="gutter-row__item" >{t("employee.full-access")}</p>, value: "full-access" },
                    { label: <p className="gutter-row__item" >{t("employee.limit-access")}</p>, value: "limit-access" },
                  ]}
                />
              </div>
              {accessLimit && <p>{t("employee.limit-description")}</p>}
            </div>
          }
        >
          <Checkbox.Group>
            {accessLimit && (
              <Row gutter={24} justify="space-between">
                {listRole.map((item) => (
                  <Col key={item._id} span={6} className="gutter-row__checkbox">
                    <h4 className="gutter-row__checkbox--title">{item.name}</h4>
                    {item.action.map((ele) => (
                      <Checkbox key={ele.key} value={ele.key} className="gutter-row__checkbox--checkbox">
                        <span className="gutter-row__checkbox--titlecheck">{ele.name}</span>
                      </Checkbox>
                    ))}
                  </Col>
                ))}
              </Row>
            )}
          </Checkbox.Group>
        </Form.Item>
      </Col>

      {isEdit ? (
        checkPermissions(Permission.ChangePass) && <Col span={24} className="employee-modal__role">
          <div className="gutter-row__container change-pass">
            <Checkbox onChange={onChange} className="gutter-row__checked">{t("employee.onchange-pass")}</Checkbox>
          </div>
          {checked ? <div className="gutter-row__pass">
            <Col span={12} className="gutter-row__pass--item">
              <Form.Item
                className="gutter-row__item"
                name="password-old"
                label={<p className="gutter-row__item" >{t("employee.password")}</p>}
                rules={[{ transform: (value) => value ? value.trim() : null, required: true, message: t("employee.password-error-null") }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="gutter-row__item"
                name="confirm_password"
                label={<p className="gutter-row__item" >{t("employee.confirm-password")}</p>}
                rules={[{ transform: (value) => value ? value.trim() : null, required: true, message: t("employee.confirm-password-error") }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </div> : null}
        </Col>

      ) : (
        <>
          <Col span={24} className="employee-modal__role">
            <div>
              <span className="gutter-row__itemtitle">{t("employee.password")}</span>
              {/* <Checkbox onChange={onChange}>Checkbox</Checkbox> */}
            </div>
            <div className="gutter-row__pass">
              <Col span={12} className="gutter-row__pass--item">
                <Form.Item
                  name="password"
                  className="gutter-row__item"
                  label={<p className="gutter-row__item" >{t("employee.password")}</p>}
                  rules={[
                    { transform: (value) => value ? value.trim() : null },
                    { required: true, message: t("employee.password-error-null") },
                    { min: 6, message: t("employee.min-error") },
                    { max: 20, message: t("employee.max-error") },
                  ]}
                >
                  <Input.Password placeholder={t("employee.password")} />
                </Form.Item>
              </Col>
              <Col span={12} >
                <Form.Item
                  name="confirm_password"
                  className="gutter-row__item"
                  label={<p className="gutter-row__item" >{t("employee.confirm-password-old")}</p>}
                  rules={[

                    { transform: (value) => value ? value.trim() : null, required: true, message: t("employee.confirm-password-error") },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t("employee.confirm-error"));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder={t("employee.confirm-password")} />
                </Form.Item>
              </Col>
            </div>

          </Col>

        </>
      )}
    </Row>
  );
};

export default EmployeeModalCreate;
