import { Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return <div>
    <Result
      status="404"
      title="404"
      subTitle={t('page.not_found')}
    />
  </div>;
};

export default NotFoundPage;
