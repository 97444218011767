import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { PageHeader, Space, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from '../../components/User/ChangePassword';
import Information from '../../components/User/Information';
import { getProfile } from '../../store/reducers/auth';
import "./index.scss";
const { TabPane } = Tabs;

const EmployeeInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(getProfile());
    }, []);
    return (
        <div className="page-info">
            <PageHeader
                className="page-title"
                title={t('information.title')}
            />
            <div className="page-content">
                <Tabs tabPosition="left">
                    <TabPane
                        tab={
                            <span>
                                <UserOutlined />
                                {t('information.profile')}
                            </span>
                        }
                        key="1">
                        <Information user={user} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <UnlockOutlined />
                                {t('information.change_password')}
                            </span>
                        }
                        key="2">
                        <ChangePassword />
                    </TabPane>

                </Tabs>

            </div>

        </div>
    );
};

export default EmployeeInfo;
