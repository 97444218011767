import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./routes";
import LoginPage from "./container/Login";
require("dotenv").config();
import { requestFirebaseNotificationPermission } from './firebaseInit';
function App() {
  requestFirebaseNotificationPermission()
    .then((firebaseToken) => {
      // eslint-disable-next-line no-console
      console.log(firebaseToken);
    })
    .catch((err) => {
      return err;
    });
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/*" component={PrivateRoute}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
