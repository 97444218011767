import { Pagination, Button } from "antd";
import { useEffect, useState } from "react";
import './index.scss';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
const CustomPagination = (prop) => {
    const { t } = useTranslation();
    const { total, current, pageSize } = prop;
    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(pageSize);
    const [defaultCurrent, setDefaultCurrent] = useState(current);
    const onChange = (pageNumber) => {
        setDefaultCurrent(pageNumber);
        setStart((pageNumber - 1) * pageSize + 1);
        if (pageNumber !== 1) {
            let endPage = pageNumber * pageSize;
            endPage = (endPage < total) ? endPage : total;
            setEnd((((pageNumber - 1) * pageSize) + pageSize) > total ? total : endPage);
        } else {
            setEnd(pageSize);
        }
        prop.onChange(pageNumber);
    };

    useEffect(() => {
        const endPage = (pageSize < total) ? pageSize : total;
        setEnd(pageSize);
    }, [pageSize]);

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return (<a type="primary">< CaretLeftOutlined className='Buttoncustom-pagination' /> </a>);
        }
        if (type === 'next') {
            return (<a type="primary"><CaretRightOutlined />  </a>);
        }
        return originalElement;
    }
    if (parseInt(total/pageSize) <= 1) return '';
    return (
        <div className="custom-paginate">
            <div className="custom-paginate__total">
                {t('Hiển thị')} {start}-{end} {t('kết quả của')} {total}
            </div>
            <div className="custom-paginate__paginate">
                <span onClick={() => onChange(1)} className="ant-pagination-item first">{t('First')}</span>
                <Pagination showSizeChanger={false} current={defaultCurrent} pageSize={pageSize} total={total} onChange={onChange} itemRender={itemRender} />
                <span onClick={() => onChange(Math.ceil(total / pageSize))} className="ant-pagination-item last">{t('Last')}</span>
            </div>
        </div>);
};

export default CustomPagination;