import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  LIST_CATEGORY_NOTIFICATION_URL,
  DELETE_CATEGORY_NOTIFICATION_URL,
  CREATE_CATEGORY_NOTIFICATION_URL,
  UPDATE_CATEGORY_NOTIFICATION_URL
} from "../../constants/endpoint";
import { callAuthorizationApi } from "../../apis/axiosClient";

const initialState = {
  isLoadingDetail: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  isLoading: false,
  data: [],
  error: false,
  errorCreate: false,
  errorUpdate: false,
  success: false,
  successCreate: false,
  successDelete: false,
  successUpdate: false,
  detail: {},
};

export const getList = createAsyncThunk("category-notification/getList", async (_, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(LIST_CATEGORY_NOTIFICATION_URL);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteCategory = createAsyncThunk("category-notification/delete", async (idCategory, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(`${DELETE_CATEGORY_NOTIFICATION_URL}/${idCategory}`, "DELETE");
    return { ...response, _id: idCategory };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createCategory = createAsyncThunk("category-notification/create", async (dataPost, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(CREATE_CATEGORY_NOTIFICATION_URL, "POST", dataPost);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDetailCategory = createAsyncThunk("category-notification/detail", async (data, { rejectWithValue }) => {
  try {
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCategory = createAsyncThunk(
  "category-notification/update",
  async ({ idCategory, dataPut }, { rejectWithValue }) => {
    try {
      const response = await callAuthorizationApi(`${UPDATE_CATEGORY_NOTIFICATION_URL}/${idCategory}`, "PUT", dataPut);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const slice = createSlice({
  name: "category_notification",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.successCreate = false;
      state.successDelete = false;
      state.successUpdate = false;
    },
  },
  extraReducers: {
    [getList.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
      state.success = false;
    },
    [getList.fulfilled]: (state, { payload: { data } }) => {
      state.data = data;
      state.isLoading = false;
      state.error = false;
      state.success = true;
    },
    [getList.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
      state.success = false;
    },
    [deleteCategory.pending]: (state) => {
      state.isLoadingDelete = true;
      state.successDelete = false;
      state.error = false;
    },
    [deleteCategory.fulfilled]: (state) => {
      state.successDelete = true;
      state.isLoadingDelete = false;
      state.error = false;
    },
    [deleteCategory.rejected]: (state) => {
      state.successDelete = false;
      state.error = true;
    },
    [createCategory.pending]: (state) => {
      state.isLoadingCreate = true;
      state.errorCreate = false;
      state.error = false;
      state.successCreate = false;
    },
    [createCategory.fulfilled]: (state) => {
      state.isLoadingCreate = false;
      state.errorCreate = false;
      state.error = false;
      state.successCreate = true;
    },
    [createCategory.rejected]: (state, { payload }) => {
      state.isLoadingCreate = false;
      state.error = true;
      state.successCreate = false;
      state.errorCreate = false;
    },
    [getDetailCategory.fulfilled]: (state, { payload }) => {
      state.detail = payload;
    },
    [updateCategory.pending]: (state) => {
      state.isLoadingUpdate = true;
      state.errorUpdate = false;
      state.error = false;
      state.successUpdate = false;
    },
    [updateCategory.fulfilled]: (state) => {
      state.isLoadingUpdate = false;
      state.errorUpdate = false;
      state.error = false;
      state.successUpdate = true;
    },
    [updateCategory.rejected]: (
      state,
      {
        payload: {
          response: { data },
        },
      }
    ) => {
      state.isLoadingUpdate = false;
      state.error = true;
      state.successUpdate = false;
      state.errorUpdate = false;
    },
  },
});

export const { resetStatus } = slice.actions;

export default slice.reducer;
