import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, notification } from "antd";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { getListTags } from "../../store/reducers/news";
import { addNewNotification, getNotificationCategories, setSuccess } from "../../store/reducers/notification";
import CreateNew from "../../components/CreateNew";
import moment from 'moment';

const CreateNotification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { error, success, isLoading, categories } = useSelector((state) => state.notification);
  const [showModal, setShowModal] = useState(false);
  const [dateDisplay, setDateDisplay] = useState(false);
  const [userType, setUserType] = useState("1");
  const [current, setCurrent] = useState(1);
  const params = {
    "keyword": "",
    "tag_type": 0,
    "page": 1,
    "per_page": 10
  };
  const onFinishForm = (values) => {
    dispatch(
      addNewNotification({
        ...values,
        status: Number(userType),
        tags:values.tags?values.tags:[],
        for_user_type: Number(values["for_user_type"]),
        public_date:values.public_date?._d?moment(new Date(values.public_date._d)).format("YYYY-MM-DD"):"",
      })
    ).then(unwrapResult);
  };
  const onChangeTextEditor = (event, editor) => form.setFieldsValue({ content: editor.getData() });

  useEffect(() => {
    if (error) {
      notification["error"]({
        message: t("common.title-error"),
        description: t("common.description-error"),
      });
    }
  }, [error]);
  useEffect(() => {
    if (success) {
      notification["success"]({
        message: t("create-news.title-success"),
        description: t("notification.create-success"),
      });
      form.resetFields();
      dispatch(setSuccess());
      history.push("/notifications");
    }
  }, [success]);

  useEffect(() => {
    dispatch(getNotificationCategories()).then(unwrapResult);
    dispatch(getListTags(params));
  }, []);

  const showModalTags = () => {
    dispatch(getListTags(params));
    setShowModal(true);
  };
  const handleCancelTags = () => {
    setShowModal(false);
  };
  const handlCheckDate =()=>{
    setDateDisplay(true);
  };
  const onCurrentPage = (current) => {
    setCurrent(current);
    params['page'] = current;
    dispatch(getListTags(params));
  };
  return (
    <div className="create-news">
      <h2>{t("notification.title")}</h2>
      <div>
        <Form layout="vertical" form={form} onFinish={onFinishForm}  requiredMark={true}>
          <CreateNew
            isLoading={isLoading}
            userType={userType}
            setUserType={setUserType}
            onChangeTextEditor={onChangeTextEditor}
            resetForm={() => form.resetFields()}
            isNotification
            categories={categories}
            showModalTags={showModalTags}
            showModal={showModal}
            handleCancelTags={handleCancelTags}
            handlCheckDate={handlCheckDate}
            dateDisplay={dateDisplay}
            setDateDisplay={(value)=>setDateDisplay(value)}
            onCurrentPage={onCurrentPage}
            current={current}
          />
        </Form>
      </div>
    </div>
  );
};

export default CreateNotification;
