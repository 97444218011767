import { useState, useCallback, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Upload, message, notification } from "antd";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { getNewsCategories } from "../../store/reducers/listnews";
import { addNewNews, clearStatus, getListTags } from "../../store/reducers/news";
import CreateNew from "../../components/CreateNew";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as ImageDefault } from "../../assets/images/image-default.svg";

const CreateNews = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { error, success, isLoading } = useSelector((state) => state.news);
  const [avatar, setAvatar] = useState("");
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [userType, setUserType] = useState("1");
  const [errorImage, setErrorImage] = useState(null);
  const [titlePreview, setTitlePreview] = useState("");
  const [source, setSource] = useState("");
  const [current, setCurrent] = useState(1);
  const [externalLink, setExternalLink] = useState("");
  const [summaryPreview, setSummaryPreview] = useState("");
  const [linkNews, setLinkNews] = useState("");
  const [dateDisplay, setDateDisplay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {categories} = useSelector(state => state.listnews);
  const params = {
    "keyword": "",
    "tag_type": 0,
    "page": 1,
    "per_page": 10
  };
  const handlCheckDate =()=>{
    setDateDisplay(true);
  };
  const handleChangeAvatar = useCallback(
    (info) => {
      if (info.file.status === "uploading") {
        setLoadingAvatar(true);
        return;
      }
      if (info.file.status === "done") {
        setLoadingAvatar(false);
        setAvatar(info.file.response.path);
      }
    },
    [loadingAvatar]
  );
  const beforeUpload = (file) => {
    setErrorImage(null);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setErrorImage(t("create-news.extension-avatar"));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setErrorImage(t("create-news.limit-size-avatar"));
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = useCallback(
    () => (
      <div className="button-upload-avatar">
        {loadingAvatar ? <LoadingOutlined /> : <ImageDefault />}
        <Button htmlType="button" type="primary" className="ant-upload-text">
          {t("create-news.description-avatar")}
        </Button>
      </div>
    ),
    [loadingAvatar]
  );

  const onFinishForm = (values) => {
    dispatch(
      addNewNews({
        ...values,
        thumbs: `${avatar}`,
        status: Number(userType),
        tags:values.tags?values.tags:[],
        public_date:values.public_date?._d?moment(new Date(values.public_date._d)).format("YYYY-MM-DD"):"",
      })
    ).then(unwrapResult);
  };
  const onChangeTextEditor = (event, editor) => form.setFieldsValue({ content: editor.getData() });

  useEffect(() => {
    dispatch(getNewsCategories());
  }, []);

  useEffect(() => {
    if (error) {
      notification["error"]({
        message: t("common.title-error"),
        description: t("common.description-error"),
      });
    }
  }, [error]);
  useEffect(() => {
    if (success) {
      notification["success"]({
        message: t("create-news.title-success"),
        description: t("create-news.description-success"),
      });
      form.resetFields();
      setAvatar("");
      setSummaryPreview("");
      setLinkNews("");
      setTitlePreview("");
      setExternalLink("");
      setSource("");
      dispatch(clearStatus());
      history.push('/list-news');
    }
  }, [success]);
  useEffect(() => {
    dispatch(getListTags(params));
  }, []);
  const showModalTags = () => {
    dispatch(getListTags(params));
    setShowModal(true);
  };
  const handleCancelTags = () => {
    setShowModal(false);
  };
  const onCurrentPage = (current) => {
    setCurrent(current);
    params['page'] = current;
    dispatch(getListTags(params));
  };
  return (
    <div className="create-news">
      <h2>{t("create-news.title")}</h2>
      <div>
        <Form layout="vertical" form={form} onFinish={onFinishForm} requiredMark={true} name="create-news">
          <CreateNew
            isLoading={isLoading}
            userType={userType}
            setUserType={setUserType}
            onChangeTextEditor={onChangeTextEditor}
            resetForm={() => form.resetFields()}
            titlePreview={titlePreview}
            setTitlePreview={(value) => setTitlePreview(value)}
            summaryPreview={summaryPreview}
            linkNews={linkNews}
            setSummaryPreview={value => setSummaryPreview(value)}
            setLinkNews={value => setLinkNews(value)}
            externalLink={externalLink}
            setExternalLink={value => setExternalLink(value)}
            source={source}
            setSource={value => setSource(value)}
            showModal={showModal}
            showModalTags={showModalTags}
            handleCancelTags={handleCancelTags}
            categories={categories}
            handlCheckDate={handlCheckDate}
            dateDisplay={dateDisplay}
            setDateDisplay={(value)=>setDateDisplay(value)}
            onCurrentPage={onCurrentPage}
            current={current}
            form={form}
          >
            <div className="create-news__box-white create-news__box-avatar">
              <Form.Item
                label={t("create-news.avatar")}
                name="thumbs"
                rules={[
                  {
                    required: true,message: t("create-news.required-avatar") ,
                  },
                  {
                     validator: () => (errorImage ? Promise.reject(errorImage):Promise.resolve()),
                  }
                ]}
              >
                <Upload
                  name="image"
                  accept="image/*"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeAvatar}
                  action={`${process.env.REACT_APP_API_URL}/api/upload-image`}
                >
                  {avatar ? (
                    <img
                      src={`${avatar}`}
                      alt="avatar"
                      className="create-news__preview"
                    />
                  ) : (
                    uploadButton()
                  )}
                </Upload>
              </Form.Item>
            </div>
          </CreateNew>
        </Form>
      </div>
    </div>
  );
};

export default CreateNews;
