import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Upload, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword, updateProfile } from '../../store/reducers/auth';
import './information.scss';
// eslint-disable-next-line no-useless-escape
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
const PHONE_VN_REGEX = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;

const ChangePassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onFinish = (values) => {
        dispatch(updatePassword({ password: values.password }));
    };
    const { updatePasswordStatus, updatePasswordMessage } = useSelector(state => state.auth);

useEffect(() => {
        if (updatePasswordStatus) {
            notification["success"]({
                message: t("information.update-password-title"),
                description: t("information.update-password-success"),
            });
        }
    }, [updatePasswordStatus]);

    useEffect(() => {
        if (updatePasswordMessage) {
            notification["error"]({
                message: t("information.update-password-title"),
                description: t("information."+ updatePasswordMessage),
            });
        }
    }, [updatePasswordMessage]);


    return (
        <div className="information">
            <Form
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={t('information.password')}
                            name="password"
                            rules={
                                [
                                    { required: true, message: t("employee.password-required") },
                                    { min: 6, message: t("employee.min-error") },
                                ]
                            }
                        >
                            <Input type="password" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="confirm_password"
                            label={t('information.repassword')}
                            rules={[

                                { transform: (value) => value ? value.trim() : null, required: true, message: t("employee.confirm-password-error") },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(t("employee.confirm-error"));
                                    },
                                }),
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" htmlType="submit">{t('information.update_password')}</Button>
            </Form>
        </div>
    );
};

export default ChangePassword;
