/* eslint-disable no-undef */
import { callApi } from "../../apis/axiosClient";

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.upload = this.upload.bind(this);
    this.abort = this.abort.bind(this);
  }
  upload() {
    return this.loader.file.then((uploadedFile) => {
      console.log("ddd");
      return new Promise((resolve) => {
        const data = new FormData();
        console.log(this.loader);
        data.append("image", uploadedFile);
        callApi("/api/upload-image", "POST", data)
          .then((res) => {
            resolve({
              default: process.env.REACT_APP_API_URL + res.data.path,
            });
          })
          .catch((error) => {
            alert(error);
            return Promise.reject(error);
          });
      });
    });
  }

  abort() {
    // Reject promise returned from upload() method.
  }
}
export default UploadAdapter;
