import { Form, Input, Mentions, Select, Tag, Checkbox, notification } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './index.scss';
import imgbroker from '../../assets/images/broker/imgbroker.png';
import TextEditor from "../editor";
import { approvedIdCard, approvedPortrait } from '../../store/reducers/user';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
const ImageBlock = (props) => {
    const { t } = useTranslation();
    const { approved } = props;
    const styles = (approved) ? 'image-info__approved' : 'image-info__unapproved';
    const title = (approved) ? t('broker.approved') : t('broker.unapproved');
    const onApproved = () => {
        props.onApproved();

    };
    return (
        <Checkbox className={`image__container--checkbox ${styles}`} checked={approved} onChange={onApproved} >
            {title}
        </Checkbox>
    );
};
const BrokerDetail = () => {
    const { user, approvedImageError } = useSelector(state => state.user);
    const { TextArea } = Input;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const approveIdCard = (key) => {
        const data = { key };
        dispatch(approvedIdCard({ userId: user._id, data: data }));
    };

    const approvePortrait = (key) => {
        const data = { key };
        dispatch(approvedPortrait({ userId: user._id, data: data }));
    };

    useEffect(() => {
        if (approvedImageError) {
            notification["error"]({
                message: t("broker.modal." + approvedImageError.toLowerCase()),
                description: t("common.description-error"),
            });
        }
    }, [approvedImageError]);
    return (
        <div className="user-detail">
            <p className="user-detail__title">{t('broker.modal.broker-infomation')}</p>
            <img className="user-detail__avatar" src={user?.avatar} />
            <Form
                className="user-detail__form"
            >
                <Form.Item
                >
                    <div className="info">
                        <div className="info__name">
                            <Input disabled value={user?.name} className="user-detail__form__item" />
                        </div>
                        <div className="info__phone">
                            <Input disabled value={user?.phone} className="user-detail__form__item" />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                >
                    <Input disabled value={user?.email} className="user-detail__form__item" />
                </Form.Item>
                <Form.Item
                >
                    <Mentions rows={3} autoSize={true} value={user?.address} disabled className="user-detail__form__item">
                    </Mentions>
                </Form.Item>

                <Form.Item>
                    <div className="info">
                        <div className="info__name">
                            <Select value={(user?.status == 1) ? "Đang hoạt động" : "Dừng hoạt động"} disabled className="user-detail__form__item">
                            </Select>
                        </div>
                        <div className="info__phone">
                            <TextArea rows={4} placeholder={t('users.modal.tag-for-this-user')} allowClear autoSize />
                            <div className="tags">
                                {user?.tags?.length && user?.tags.map((item, key) => {
                                    if (Object.keys(item).length != 0) {
                                        return <Tag
                                            closable
                                            visible
                                            key={key}
                                            className="tags__tag"
                                        >
                                            {item.name}
                                        </Tag>;
                                    }
                                })}

                            </div>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    className="user-detail__form--ml10"
                >
                    <span className="user-detail__form--span">{t('broker.modal.photo-id-card-or-id-card')}</span>
                    <div className="image">
                        <div className="image__container image-info">
                            <p className="image-info__title">{t('broker.modal.idcard_frontside')}</p>
                            <img src={user?.id_card_image[0] ? user.id_card_image[0].url : imgbroker} className="image__container--item"></img>
                            <ImageBlock approved={user?.id_card_image[0]?.approved} onApproved={() => approveIdCard(0)} />
                        </div>
                        <div className="image__container image-info">
                            <p className="image-info__title">{t('broker.modal.idcard_backside')}</p>
                            <img src={user?.id_card_image[1] ? user.id_card_image[1] : imgbroker} className="image__container--item"></img>
                            <ImageBlock approved={user?.id_card_image[1]?.approved} onApproved={() => approveIdCard(1)} />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item className="user-detail__form--ml10">
                    <span className="user-detail__form--span">{t('broker.modal.portrait')}</span>
                    <div className="image">
                        {user?.portrait_image.length > 0 ?
                            user?.portrait_image.map((item, key) => {
                                return (
                                    <div className="image__container image-info" key={key}>
                                        <img src={user?.portrait_image[key] ? user.portrait_image[key].url : imgbroker} className="image__container--item"></img>
                                        <ImageBlock approved={user?.portrait_image[key]?.approved} onApproved={() => approvePortrait(key)} />
                                    </div>
                                );
                            }) :
                            (
                                <div className="image__container image-info">
                                    <img src={imgbroker} className="image__container--item"></img>
                                    <ImageBlock approved={false} onApproved={() => { }} />
                                </div>
                            )
                        }

                    </div>
                </Form.Item>
                <Form.Item className="user-detail__form--ml10">
                    <span className="user-detail__form--span">{t('broker.modal.active-area(10km radius from the selected point)')}</span>
                    <div className="input">
                        {user?.locations.length > 0 && user?.locations.map((item, key) => {
                            return (
                                <Input key={key} disabled className="input__item" placeholder={t('broker.modal.address')} value={item.name} />

                            );
                        })}
                    </div>
                </Form.Item>
                <Form.Item className="user-detail__form--ml10">
                    <span className="user-detail__form--span">{t('broker.modal.introduce-yourself')}</span>
                    <div className="editor">
                        <TextEditor
                            placeholder={t("update-news.description-content")}
                            datadetail={user?.introduction}
                            disabled={true}
                        />
                        <div className="image">
                            {user?.certicate_image?.length > 0 ? user.certicate_image.map((item, key) => {
                                return (<div className="image__container image-info" key={key}>
                                    <img src={item} className="image__container--item"></img>
                                </div>);
                            }) : <div className="image__container">
                                <img src={imgbroker} className="image__container--item"></img>
                            </div>}
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default BrokerDetail;
