import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CREATE_NOTIFICATION_URL, CATEGORIES_NOTIFICATION_URL } from "../../constants/endpoint";
import { callAuthorizationApi } from "../../apis/axiosClient";

const initialState = {
  isLoading: false,
  status: "idle",
  data: [],
  categories: [],
  error: false,
  success: false,
};

export const addNewNotification = createAsyncThunk(
  "notification/addNewNotification",
  async (dataPost, { rejectWithValue }) => {
    try {
      const response = await callAuthorizationApi(CREATE_NOTIFICATION_URL, "POST", dataPost);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotificationCategories = createAsyncThunk("notification/categories", async (_, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(CATEGORIES_NOTIFICATION_URL);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setSuccess: (state) => {
      state.success = false;
    },
  },
  extraReducers: {
    [getNotificationCategories.fulfilled]: (state, { payload: { data } }) => {
      const serializeData = data.map((item) => ({ value: item._id, label: item.name }));
      state.categories = serializeData;
    },
    [addNewNotification.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
      state.success = false;
    },
    [addNewNotification.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.data.push(action.payload.data);
    },
    [addNewNotification.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
      state.success = false;
    },
  },
});

export const { setSuccess } = slice.actions;
export default slice.reducer;
