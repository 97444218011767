import { Table, Button, Modal, PageHeader, Form, Popconfirm, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import '../../assets/styles/index.scss';
import CustomTable from '../../components/CustomTable';
import {
  createEmployee,
  deleteEmployee,
  getDetailEmployee,
  getListEmployee,
  getListRole,
  removeDetailEmployee,
  updateEmployee,
  resetStatus
} from "../../store/reducers/employee";
import EmployeeModalCreate from "../../components/EmployeeModalCreate";
import "./employee.scss";
import { PER_PAGE } from "../../constants";
import CustomPagination from '../../components/CustomPagination';
import { checkPermissions } from '../../helpers/role';
import { Permission } from '../../helpers/permission';

const FormatDate = (created_at) => <p className="mb-0">{moment(created_at).format("DD/MM/YYYY")}</p>;

const Employees = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    data,
    isLoading,
    isLoadingDetail,
    isLoadingCreate,
    isLoadingUpdate,
    error,
    errorCreate,
    errorUpdate,
    successCreate,
    successDelete,
    successUpdate,
    listRole,
    total,
    detail,
    per_page
  } = useSelector((state) => state.employees);
  const { user } = useSelector((state) => state.auth);
  const [dataDelete, setDataDelete] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [accessLimit, setAccessLimit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [current, setCurrent] = useState(1);
  const [checked, setChecked] = useState(false);
  function onChange(e) {
    setChecked(e.target.checked);
    // console.log(`checked = ${e.target.checked}`);
  }
  const onFinishForm = (values) => {
    if (isEdit) {
      dispatch(updateEmployee({ idEmployee: detail._id, dataPut: { ...values, access_limit: accessLimit } })).then(
        unwrapResult
      );
      setChecked(false);
    } else {
      dispatch(createEmployee({ ...values, access_limit: accessLimit })).then(unwrapResult);
    }
  };


  const onCurrentPage = (current) => {
    setCurrent(current);
    // params['page'] = current;
    // getSearchApi(params);
  };

  useEffect(() => {
    if (error) {
      if (errorCreate) {
        notification.error({ message: t("common.title-error"), description: t("employee.create-error") });
      } else if (errorUpdate) {
        notification.error({ message: t("common.title-error"), description: t("employee.update-error") });
      } else {
        notification.error({ message: t("common.title-error"), description: t("common.description-error") });
      }
    }
  }, [error, errorCreate, errorUpdate]);

  useEffect(() => {
    if (successDelete) {
      notification.success({ message: t("common.title-success"), description: t("employee.delete-success") });
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        dispatch(getListEmployee({ page: 1, per_page: PER_PAGE }));
      }
    }
  }, [successDelete]);
  useEffect(() => {
    if (successCreate) {
      notification.success({ message: t("common.title-success"), description: t("employee.create-success") });
      form.resetFields();
      setVisibleModal(false);
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        dispatch(getListEmployee({ page: 1, per_page: PER_PAGE }));
      }
    }
  }, [successCreate]);
  useEffect(() => {
    if (successUpdate) {
      notification.success({ message: t("common.title-success"), description: t("employee.update-success") });
      form.resetFields();
      setIsEdit(false);
      setVisibleModal(false);
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        dispatch(getListEmployee({ page: 1, per_page: PER_PAGE }));
      }
    }
  }, [successUpdate]);

  useEffect(() => {
    dispatch(getListEmployee({ page: currentPage, per_page: PER_PAGE }));
  }, [currentPage]);

  useEffect(() => {
    if (detail._id) {
      form.setFieldsValue({ ...detail, password: "" });
      setAccessLimit(detail.access_limit);
    } else {
      form.resetFields();
      setAccessLimit(false);
    }
  }, [detail]);

  useEffect(() => {
    dispatch(getListRole());
    return function cleanup() {
      dispatch(resetStatus());
    };
  }, []);

  const handleClickDelete = (e) => {
    setIsModalVisible(true);
    setDataDelete(e._id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    dispatch(deleteEmployee(dataDelete));
    setDataDelete(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDataDelete(null);
  };
  const closeModal = () => {
    setVisibleModal(false);
    setAccessLimit(false);
    setIsEdit(false);
    removeDetailEmployee();
    form.resetFields();
  };
  const checkRole = (e) => {
    if (e.access_limit) {
      return <span>{t('employee.limit-access')}</span>;
    } else {
      return <span>{t('employee.full-access')}</span>;
    }
  };
  return (
    <>
      <Modal
        onCancel={() => {
          setVisibleModal(false);
          setAccessLimit(false);
          setIsEdit(false);
          removeDetailEmployee();
          setChecked(false);
          form.resetFields();
        }}
        className="modal-user"
        visible={visibleModal}
        footer={null}
        title={isEdit ? t("employee.update") : t("employee.create")}
        forceRender
        width="720px"
      >
        <Form layout="vertical" form={form} name="modal-create-employee" onFinish={onFinishForm} initialValues={{
          email: null
        }}>
          <EmployeeModalCreate
            listRole={listRole}
            setAccessLimit={setAccessLimit}
            accessLimit={accessLimit}
            isEdit={isEdit}
            checked={checked}
            onChange={onChange}
          />
          <div className={isEdit ? "employee-modal__footer" : "employee-modal__footer-create"}>
            {
              isEdit && checkPermissions(Permission.DeleteEmployee) && <div className="employee-modal__footer--delete">
                <a className="employee-modal__footer--item">{t('employee.delete-user')}</a>
              </div>
            }
            <div className="employee-modal__footer--action">
              <Button htmlType="button" onClick={() => closeModal()} className="employee-modal__footer--button">
                {t("employee.cancel")}
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
                {t("employee.save")}
              </Button>
            </div>

          </div>
        </Form>
      </Modal>
      <div className="page-empolyee">
        <PageHeader
          className="page-title"
          title={t('employee.title')}
          extra={
            [
              checkPermissions(Permission.CreateEmployee) && <Button
                key={1}
                type="primary"
                htmlType="button"
                onClick={() => {
                  setVisibleModal(true);
                  setIsEdit(false);
                }}>
                {t('employee.create')}
              </Button>

            ]
          }
        />
        <div className="page-content">
          <div className="table-list">
            <CustomTable data={data} loading={isLoading} rowselect={false}>
              <Table.Column
                align="center"
                title={t('employee.account')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className="table-list__row--checktitle">
                      { e.name}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.phone')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className="table-list__row--checktitle">
                      { e.phone}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.email')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className="table-list__row--column">
                      { e.email}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.date')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className='table-list__row--column'>
                      { moment(e.created_at).format('DD/MM/YYYY')}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.address')}
                className="table-list__row--setwidth"
                render={(e) => {
                  return (
                    <span className="table-list__row--column">
                      { e.address}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.social')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className="table-list__row--column">
                      { e.facebook}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.rules')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className="table-list__row--column">
                      { checkRole(e)}
                    </span>
                  );
                }}
              />
              {checkPermissions(Permission.UpdateEmployee) && checkPermissions(Permission.DeleteEmployee) &&
                <Table.Column
                  align="center"
                  title={t('employee.action')}
                  className="table-list__row"
                  render={(e) => {
                    return (
                      <div className="action">
                        { checkPermissions(Permission.UpdateEmployee) && <a onClick={() => {
                          setVisibleModal(true);
                          setIsEdit(true);
                          dispatch(getDetailEmployee(e._id)).then(unwrapResult);
                        }} className="action__delete">
                          <EditOutlined className="action__delete__icon" />
                          <span className="action__delete__titledelete">{t("employee.edit")}</span>
                        </a>
                        }
                        { checkPermissions(Permission.DeleteEmployee) &&
                          <a className="action__delete" onClick={() => handleClickDelete(e)}>
                            <DeleteOutlined className="action__delete__icon" />
                            <span className="action__delete__titledelete">{t("employee.delete")}</span>
                          </a>
                        }
                      </div>
                    );
                  }}
                />}
            </CustomTable>
            <CustomPagination total={total} current={current} perPage={per_page} pageSize={per_page || 10} onChange={(e) => onCurrentPage(e)} />
          </div>
          <Modal centered title={t('employee.modal-notifycation')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <h3>{t('employee.are-you-sure-you-want-to-change-the-delete')}</h3>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Employees;
