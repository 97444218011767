import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

const CategoryNewsModalCreate = () => {
  const { t } = useTranslation();
  
  return (
    <Row gutter={24}>
      <Col className="gutter-row" span={24}>
        <Form.Item name="name" className="gutter-row__item" label={<p className="gutter-row__item" >{t("category-news.name")}</p>} rules={[{ required: true, message: t("category-news.name-error") }]}>
          <Input placeholder={t("category-news.name")} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CategoryNewsModalCreate;
