import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import ListUser from "../container/ListUser";
import RedirectToPage from "../components/RedirectToPage";
import { LIST_MENU } from "../constants/menu";
import ScrollToTopWrapper from "../components/ScrollToTopWrapper";
import LayoutDashBoard from "../components/layout";
import HomePage from "../container/Home";
import Broker from "../container/BrokerList";
import NotFoundPage from "../container/NotFoundPage";
import CreateNews from "../container/CreateNews";
import UpdateNews from "../container/UpdateNews";
import ListNews from '../container/ListNews';
import ListNotification from "../container/ListNotification";
import CreateNotification from "../container/CreateNotification";
import Employees from "../container/Employee";
import CategoryNews from "../container/CategoryNews";
import CategoryNotification from "../container/CategoryNotification";
import SmsReport from "../container/SmsReport";
import NotAuthorized from "../container/NotAuthorized";
import EmployeeInfo from "../container/EmployeeInfo";
const PrivateRoute = () => {
  const { isAuthUser, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      return <RedirectToPage to="/login" />;
    }
  }, [user]);

  if (!isAuthUser && !localStorage.getItem("user")) {
    return <RedirectToPage to="/login" />;
  }
  return (
    <LayoutDashBoard menuData={LIST_MENU}>
      <ScrollToTopWrapper>
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route exact path="/create-news" component={CreateNews}></Route>
          <Route exact path="/users" component={ListUser} />
          <Route exact path="/broker" component={Broker}></Route>
          <Route exact path="/list-news" component={ListNews}></Route>
          <Route exact path="/notifications" component={ListNotification}></Route>
          <Route exact path="/create-notification" component={CreateNotification}></Route>
          <Route exact path="/employees" component={Employees}></Route>
          <Route exact path="/category-notification" component={CategoryNotification}></Route>
          <Route exact path="/category-news" component={CategoryNews}></Route>
          <Route exact path="/UpdateNews/:id" component={UpdateNews}></Route>
          <Route exact path="/sms-report" component={SmsReport}></Route>
          <Route exact path="/info" component={EmployeeInfo}></Route>

          <Route exact path="/403" component={NotAuthorized}></Route>
          <Route component={NotFoundPage} />
        </Switch>
      </ScrollToTopWrapper>
    </LayoutDashBoard>
  );
};

export default PrivateRoute;
