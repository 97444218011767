import React, { useRef, useState } from "react";
import { Line } from "@ant-design/charts";
import { Menu, Card, PageHeader, Select, DatePicker } from "antd";
import { Content } from "antd/lib/layout/layout";
// import { Option } from "antd/lib/mentions";
// import Search from "antd/lib/transfer/search";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import iconuser from "../../assets/images/img-menu/userlist.svg";
import { useTranslation } from "react-i18next";
import "./home.scss";
import { getDataChart } from "../../store/reducers/home";
import moment from "moment";
import { set } from "immutable";
import { getProfile } from "../../store/reducers/auth";

const { Option } = Select;

const getMonday = (d) => {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

const getStartMonth = (d) => {
  const date = new Date(d);
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const { RangePicker } = DatePicker;

const HomePage = () => {
  const { dataChart, total, subTotal } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef();
  const monday = getMonday(new Date()).toISOString();
  const startOfMonth = getStartMonth(new Date()).toISOString();
  const current = new Date().toISOString();
  const [from, setFrom] = useState(monday);
  const [end, setEnd] = useState(current);
  const [range, setRange] = useState(false);
  const [time, setTime] = useState('week');
  // Get user info 
  useEffect(() => {
    dispatch(getProfile());
  },[]);
  useEffect(() => {
    dispatch(getDataChart({ time, from: from, to: end }));
  }, [time, from, end]);

  const handleChange = (item) => {
    setRange(false);
    switch (item.value) {
      case "1":
        setTime('week');
        break;
      case "2":
        setTime('month');
        break;
      case "3":
        setTime('year');
        break;

      case "4":
        setTime('range');
        setRange(true);
        break;

    }
  };

  const onChangeRangeData = (item) => {
    setFrom(item[0].toISOString());
    setEnd(item[1].toISOString());

  };

  const config = {
    data: dataChart,
    height: 300,
    xField: "key",
    yField: "value",
    seriesField: "name",
    point: {
      size: 5,
      shape: "diamond",
      // style: {
      //   fill: "white",
      //   stroke: "#2593fc",
      //   lineWidth: 2,
      // },
    },
  };
  const { SubMenu } = Menu;
  return (
    <div className="home-page">
      <PageHeader className="page-title" title={t("home.title")} />
      <Content className="page-content">
        <Card bordered={false}>
          <div className="info-home">
            <div className="dropdown">
              {range && <RangePicker
                format={"YYYY/MM/DD"}
                onChange={onChangeRangeData}
                className="select-range"
              />}
              <Select
                labelInValue
                defaultValue={{ value: '1' }}
                onChange={handleChange}
              >
                <Option value="1">{t("home.select.week")}</Option>
                <Option value="2">{t("home.select.month")}</Option>
                <Option value="3">{t("home.select.year")}</Option>
                <Option value="4">{t("home.select.range")}</Option>

              </Select>

            </div>
            <ul className="info-home__container">
              <div className="user">
                <div className="user__total">
                  <p className="user__total--title">{t("home.user")}</p>
                  <p className="user__total--item">
                    <span className="user__total--sub line-user">{subTotal?.client}</span>
                    /
                    {total?.client}
                  </p>
                </div>
                <div className="user__icon">
                  <img src={iconuser} alt="" />
                </div>
              </div>
              <div className="user broker">
                <div className="user__total">
                  <p className="user__total--title">{t("home.broker")}</p>
                  <p className="user__total--item">
                    <span className="user__total--sub line-broker">{subTotal?.broker}</span>
                    /
                    {total?.broker}
                  </p>
                </div>
                <div className="user__icon">
                  <img src={iconuser} alt="" />
                </div>
              </div>
              <div className="connect">
                <div className="user__total">
                  <p className="user__total--title">{t("home.total-connect")}</p>
                  <p className="user__total--item">
                    <span className="user__total--sub line-connect">{subTotal?.connect}</span>
                    /
                    {total?.connect}
                  </p>
                </div>
                <div className="user__icon">
                  <img src={iconuser} alt="" />
                </div>
              </div>
            </ul>
            <div className="char">
              <Line {...config} chartRef={ref} />
            </div>
          </div>
        </Card>
      </Content>
    </div>
  );
};

export default HomePage;
