import { Form, Input, Mentions, Select, Tag } from 'antd';
import { Option } from 'antd/lib/mentions';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './index.scss';
const UserDetail = () => {
    const { user } = useSelector(state => state.user);
    const { TextArea } = Input;
    const { t } = useTranslation();
    const tag=[{name:"nhà đất"},{name:"vip"}];
    return (
        <div className="user-detail">
            <p className="user-detail__title">Thông tin người dùng</p>
            <img className="user-detail__avatar" src={user?.avatar} />
            <Form
                className="user-detail__form"
            >
                <Form.Item
                >
                    <Input disabled value={user?.name} className="user-detail__form__item" />
                </Form.Item>
                <Form.Item
                >
                    <Input disabled value={user?.phone} className="user-detail__form__item" />
                </Form.Item>
                <Form.Item
                >
                    <Input disabled value={user?.email} className="user-detail__form__item" />
                </Form.Item>
                <Form.Item
                >
                    <Mentions rows={3} autoSize={true} value={user?.address} disabled className="user-detail__form__item">
                    </Mentions>
                </Form.Item>

                <Form.Item>
                    <Select value={(user?.status == 1) ? "Đang hoạt động" : "Dừng hoạt động"} disabled className="user-detail__form__item">
                    </Select>
                </Form.Item>
                <Form.Item>
                    <TextArea rows={4} placeholder={t('users.modal.tag-for-this-user')} allowClear  disabled/>
                </Form.Item>
            </Form>
            <div className="tags">
                {user?.tags.length > 0 && user?.tags.map((item, key) => {
                    if (Object.keys(item).length != 0) {
                        return <Tag
                            closable
                            visible
                            className="user-detail__tag"
                            key={key}
                        >
                            {item.name}
                        </Tag>;
                    }

                })}

            </div>
        </div>
    );
};

export default UserDetail;
