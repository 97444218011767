import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERCH_USER_URL, UPDATE_STATUS_URL, GET_LISTTAGS, DETAIL_USER_URL, UPDATE_APPROVED_ID_CARD, UPDATE_APPROVED_PORTRAIT } from '../../constants/endpoint';
import { callApi, callAuthorizationApi } from "../../apis/axiosClient";

export const getUserDetail = createAsyncThunk("user/detail", async (userId, { rejectWithValue }) => {
    try {
        const response = await callAuthorizationApi(`${DETAIL_USER_URL}/${userId}`);
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const approvedIdCard = createAsyncThunk("user/approvedIdCard",

    async ({ userId, data }, { rejectWithValue }) => {
        try {
            const response = await callAuthorizationApi(`${UPDATE_APPROVED_ID_CARD}/${userId}`, "PUT", data);
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }

    }
);

export const approvedPortrait = createAsyncThunk("user/approvedPortrait",

    async ({ userId, data }, { rejectWithValue }) => {
        try {
            const response = await callAuthorizationApi(`${UPDATE_APPROVED_PORTRAIT}/${userId}`, "PUT", data);
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }

    }
);

const slice = createSlice({
    name: 'users',
    initialState: {
        data: [],
        isLoading: true,
        total: 0,
        perPage: 0,
        listtags: [],
        user: null,
        approvedImageError: null
    },
    reducers: {
        getUserSuccess: (state, action) => {
            state.data = action.payload.data.data;
            state.total = action.payload.data.total;
            state.per_page = action.payload.data.per_page;
            state.isLoading = false;
        },
        getUserError: (state, action) => {
            state.isLoading = false;
        },
        updateStatusSuccess: (state, action) => {
            const index = state.data.findIndex((data) => data._id === action.payload.id);
            state.data[index].status = action.payload.status;
            state.data = [...state.data];
            state.isLoading = false;
        },
        updateStatusError: (state, action) => {
            state.isLoading = false;
        },
        getApiListTagsSuccess: (state, action) => {
            state.listtags = action.payload.data;
            state.isLoading = false;
        },
        getApiListTagsError: (state, action) => {
            state.isLoading = false;
        },
    },
    extraReducers: {
        [getUserDetail.fulfilled]: (state, { payload: { data } }) => {
            state.user = data;
        },
        [approvedIdCard.fulfilled]: (state, { payload: { data } }) => {
            state.user = data;
        },
        [approvedIdCard.pending]: (state) => {
            state.approvedImageError = null;
        },
        [approvedIdCard.rejected]: (
            state,
            {
                payload: {
                    response: { data }
                },
            }

        ) => {
            state.approvedImageError = data.message;
        },

        [approvedPortrait.fulfilled]: (state, { payload: { data } }) => {
            state.user = data;
        },
        [approvedPortrait.pending]: (state) => {
            state.approvedImageError = null;
        },
        [approvedPortrait.rejected]: (
            state,
            {
                payload: {
                    response: { data }
                },
            }

        ) => {
            state.approvedImageError = data.message;
        },
    }

});

export default slice.reducer;

// Actions

const { getUserSuccess, getUserError, getApiListTagsSuccess, getApiListTagsError, updateStatusSuccess, updateStatusError } = slice.actions;

export const searchUser = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(SERCH_USER_URL, 'POST', params);
        return dispatch(getUserSuccess(response));
    } catch (e) {
        return dispatch(getUserError(e));
    }
};

export const onChangeStatus = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${UPDATE_STATUS_URL}/${params.id}`, 'PUT', { 'status': params.status });
        return dispatch(updateStatusSuccess(params));
    } catch (e) {
        return dispatch(updateStatusError(params));
    }
};

export const getApiListTags = () => async dispatch => {
    try {
        const response = await callAuthorizationApi(GET_LISTTAGS, 'GET', {});
        return dispatch(getApiListTagsSuccess(response));
    } catch (e) {
        return dispatch(getApiListTagsError());
    }
};
