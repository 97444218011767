import React, { useState, useEffect } from 'react';
import { Tabs, Select, Input, Form, PageHeader, Button, Table, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import './index.scss';
import { getAuths, getNewsCategories, getNotificationCategories } from '../../store/reducers/listnews';
import CustomPagination from '../../components/CustomPagination';
import CustomTable from '../../components/CustomTable';
import moment from 'moment';
import { getListTags } from "../../store/reducers/news";
import { useDispatch, useSelector } from "react-redux";
import { searchListNotification, onChangeNotificationsStatus, getApiBrokerListTags } from '../../store/reducers/list_notification';
import '../../assets/styles/index.scss';
import { useHistory } from 'react-router-dom';
import { checkPermissions } from '../../helpers/role';
import { Permission } from '../../helpers/permission';

const ListNotification = () => {
    const history = useHistory();

    const { t } = useTranslation();

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const { data, total, per_page, listtags } = useSelector(state => state.listNotification);
    const { auths, categories, usertypes, notificationCategories } = useSelector(state => state.listnews);
    const [valuesSelect, setValuesSelect] = useState([]);
    const [current, setCurrent] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [perPage, setPerPage] = useState(20);
    const [status, setStatus] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataUpdateNotifications, setDataUpdateNotifications] = useState(null);
    const { dataListTags } = useSelector((state) => state.news);
    const params =
    {
        "page": 1,
        "per_page": 10,
        "keyword": "",
        "tags": [],
        "created_by": "",
        "status": 0,
        "notify_category_id": "",
        "for_user_type": 0
    };
    const dataTags = {
        "keyword": "",
        "tag_type": 0,
        "page": 1,
        "per_page": 10
    };
    const onCurrentPage = (current) => {
        params['page'] = current;
        dispatch(searchListNotification(params));

    };

    const onChangetabs = (e) => {
        setStatus(parseInt(e));
        params['status'] = parseInt(e);
        resetPage();
        getSearchApi(params);
    };

    const resetPage = () => {
        setCurrent(1);
        params['page'] = 1;
    };

    const getSearchApi = (params) => {
        dispatch(searchListNotification(params));
    };

    const onSearchKeyword = (e) => {
        setKeyword(e);
        params['keyword'] = e;
        resetPage();
        getSearchApi(params);
    };


    const handleClickStatus = (e) => {
        var params = {
            id: e._id,
            status: 0
        };
        if (parseInt(e.status) === 2) {
            params = {
                id: e._id,
                status: 1
            };
            setDataUpdateNotifications(params);
            setIsModalVisible(true);
        }
    };

    const handleOk = () => {
        dispatch(onChangeNotificationsStatus(dataUpdateNotifications));
        setIsModalVisible(false);
        setDataUpdateNotifications(null);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setDataUpdateNotifications(null);
    };


    const checkObject = (object) => {
        if (parseInt(object) === 1) {
            return t('list-notification.table-clumn.customer');
        } else if (parseInt(object) === 2) {
            return t('list-notification.table-clumn.broker');
        } else if (parseInt(object) === 3) {
            return t('list-notification.table-clumn.all');
        }
    };

    const onChangeUrl = () => {
        history.push('/create-notification');
    };

    const handleValueSelect = (e) => {
        params['tags'] = e;
        dispatch(searchListNotification(params));
    };

    const handleClose = (valueSelect) => {
        const newSelectValues = valuesSelect.filter(e => e !== valueSelect);
        setValuesSelect(newSelectValues);
        form.setFieldsValue({ tag: newSelectValues });
        if (newSelectValues.length === 0) {
            params['tags'] = [];
            dispatch(searchListNotification(params));
        }
    };
    useEffect(() => {
        dispatch(searchListNotification(params));
        dispatch(getApiBrokerListTags());
        dispatch(getAuths());
        dispatch(getNewsCategories());
        dispatch(getNotificationCategories());
        dispatch(getListTags(dataTags));
    }, [dispatch]);
    const handleSelectAuth = (value) => {
        if (typeof (value) === "undefined") {
            params['created_by'] = '';
            dispatch(searchListNotification(params));
        } else {
            params['created_by'] = value;
            dispatch(searchListNotification(params));

        }
    };

    const handleSelectCategory = (value) => {
        if (value === undefined) {
            params['notify_category_id'] = "";
            dispatch(searchListNotification(params));
        } else {
            params['notify_category_id'] = value;
            dispatch(searchListNotification(params));
        }
    };

    const handleSelectUserType = (value) => {
        if (value === undefined) {
            params['for_user_type'] = 0;
            dispatch(searchListNotification(params));
        } else {
            params['for_user_type'] = value;
            dispatch(searchListNotification(params));
        }
    };


    return (

        <div className="page-notification">
            <PageHeader
                className="page-title"
                title={t('list-notification.title')}
                extra={
                    [
                        checkPermissions(Permission.CreateNotify) && <Button key={1} type="primary" className="page-news__page-title" onClick={() => onChangeUrl()}>{t('list-notification.create-notification')}</Button>

                    ]
                }
            />
            <div className="page-content">
                <div className="tabs">
                    <Tabs
                        defaultActiveKey="0"
                        className="tabs"
                        onChange={(e) => onChangetabs(e)}
                    >
                        <Tabs.TabPane
                            tab={t('list-notification.tab.all-articles')}
                            key="0"
                        >
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={t('list-notification.tab.unset')}
                            key="2"
                        >
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={t('list-notification.tab.sent')}
                            key="1"
                        >
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                <div className="form-search">
                    <Form
                        onFieldsChange={(e) => {
                            setValuesSelect(form.getFieldValue("tag"));
                        }}
                        form={form}
                        name="control-hooks"
                        className="form-search__form"
                    >
                        <Input.Group compact>
                            <Form.Item name="tag">
                                <Select className='form-search__tag-select'
                                    mode="multiple"
                                    placeholder={t('list-notification.form.Tagged-with')}
                                    showArrow
                                    onChange={(e) => handleValueSelect(e)}
                                    autoFocus={false}
                                >
                                    {dataListTags?.length ? dataListTags.map(item => (
                                        <Select.Option key={item.name}>{item.name}</Select.Option>
                                    )) : null};
                                </Select>

                            </Form.Item>
                            <Form.Item name="created_by">
                                <Select className='form-search__tag-select'
                                    allowClear
                                    placeholder={t('list-notification.form.creator')}
                                    showArrow
                                    onChange={handleSelectAuth}
                                    autoFocus={false}
                                >
                                    {/* <Select.Option key="">{t('news.form.author')}</Select.Option> */}
                                    {auths.length ? auths.map(item => (
                                        <Select.Option key={item.value}>{item.label}</Select.Option>
                                    )) : null};
                                </Select>

                            </Form.Item>
                            <Form.Item name="notify_category_id">
                                <Select className='form-search__tag-select'
                                    placeholder={t('list-notification.form.category')}
                                    showArrow
                                    allowClear
                                    onChange={e => handleSelectCategory(e)}
                                    autoFocus={false}
                                >
                                    {notificationCategories.length ? notificationCategories.map(item => (
                                        <Select.Option key={item.value}>{item.label}</Select.Option>
                                    )) : null};
                                </Select>

                            </Form.Item>
                            <Form.Item name="for_user_type">
                                <Select className='form-search__tag-select'
                                    placeholder={t('list-notification.form.object')}
                                    showArrow
                                    allowClear
                                    onChange={e => handleSelectUserType(e)}
                                    autoFocus={false}
                                >
                                    {usertypes.length ? usertypes.map(item => (
                                        <Select.Option key={item.value}>{item.label}</Select.Option>
                                    )) : null};
                                </Select>

                            </Form.Item>
                            <Form.Item
                                className='form-search__input-search'
                                name="keySearch"
                            >
                                <Input.Search autoFocus={false} onSearch={(e) => onSearchKeyword(e)} allowClear placeholder={t('list-notification.form.find-all-the-article')} />
                            </Form.Item >
                        </Input.Group>
                    </Form>
                    {form.getFieldValue("tag") ? <div className="form-search__list-tag-select">
                        {valuesSelect.map(valueSelect => {
                            return (
                                <span
                                    className="form-search__list-tag-select__item"
                                    key={valueSelect}>
                                    {valueSelect}
                                    <CloseOutlined
                                        className="form-search__list-tag-select__iconclose"
                                        onClick={() => handleClose(valueSelect)}
                                    />
                                </span>
                            );
                        })}
                    </div> : null}
                </div>
                <div className="table-list">
                    <CustomTable data={data} rowselect={true}>
                        <Table.Column
                            title={t('list-notification.table-clumn.title')}
                            className="table-list__row--title"
                            render={(e) => {
                                return (
                                    <div className="table-list__row--titlenotification">
                                        <span className={parseInt(e.status) === 1 ? 'table-list__row table-list__row--titleactive' : 'table-list__row table-list__row--titledisabled'}>{e.title}</span>
                                    </div>

                                );

                            }}
                        />
                        <Table.Column
                            title={t('list-notification.table-clumn.object')}
                            align='center'
                            className="table-list__row"
                            render={(e) => {
                                return (
                                    <span className="table-list__row--column">
                                        { checkObject(e.for_user_type)}
                                    </span>
                                );
                            }}
                        />
                        <Table.Column
                            title={t('list-notification.table-clumn.category')}
                            align='center'
                            className="table-list__row"
                            render={(e) => {
                                return (
                                    <span className="table-list__row--column">
                                        { e?.notify_category_id?.name}
                                    </span>
                                );
                            }}
                        />
                        <Table.Column
                            title={t('list-notification.table-clumn.creator')}
                            align='center'
                            className="table-list__row"
                            render={(e) => {
                                return (
                                    <span className="table-list__row--column">
                                        { e?.created_by?.name}
                                    </span>
                                );
                            }}
                        />
                        <Table.Column
                            title={t('list-notification.table-clumn.time-sent')}
                            align='center'
                            className="table-list__row"
                            render={(e) => {
                                if(e.status == 1) {
                                    return (
                                        <span className="table-list__row--column">
                                            { moment(e.updated_at).format('DD/MM/YYYY')}
                                            <br />
                                            { moment(e.updated_at).format('H:mm')}
                                        </span>
    
                                    );
                                }
                                else if(e.public_date) {
                                    return (
                                        <span className="table-list__row--column">
                                            { moment(e.public_date).format('DD/MM/YYYY')}
                                            <br />
                                            { moment(e.public_date).format('h:mm')}
                                        </span>
    
                                    );
                                }
                                else {
                                    return "";
                                }
                                
                            }}
                        />
                        {checkPermissions(Permission.UpdateNoitfy) && <Table.Column
                            title={t('list-notification.table-clumn.status')}
                            align='center'
                            className="table-list__row"
                            render={(e) => {
                                return (
                                    <div className="action">
                                        <div className="action__post">
                                            <CheckOutlined className={parseInt(e.status) === 1 ? 'action__post action__post--active' : 'action__post action__post--inactive'} />
                                            <span className="action__post--title" onClick={() => handleClickStatus(e)}>{parseInt(e.status) === 1 ? t('list-notification.tab.sent') : t('list-notification.tab.unset')}</span>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                        }
                    </CustomTable>
                    <CustomPagination total={total} current={current} perPage={per_page} pageSize={per_page || 10} onChange={(e) => onCurrentPage(e)} />
                </div>
                <Modal centered title={t('list-notification.modal.notifycation')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <h3>{t('list-notification.modal.Are-you-sure-you-want-to-change-the-status')}</h3>
                </Modal>
            </div>
        </div>
    );
};

export default ListNotification;
