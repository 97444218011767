import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_DASHBOARD } from "../../constants/endpoint";
import { callAuthorizationApi } from "../../apis/axiosClient";

const serializeData = (data) => {
  let result = [];
  Object.keys(data).forEach((item) =>
    result.push(...data[item].map((ele) => ({ ...ele, name: capitalizeTheFirstLetterOfEachWord(item) })))
  );
  return result;
};
const capitalizeTheFirstLetterOfEachWord = (words) => {
  let separateWord = words.toLowerCase().split(" ");
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

const initialState = {
  total: null,
  subTotal: null,
  dataChart: [],
};

export const getDataChart = createAsyncThunk("home/data-chart", async (params, { rejectWithValue }) => {
  try {
    console.log(params);
    const response = await callAuthorizationApi(GET_DASHBOARD, "GET", {}, params);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    [getDataChart.fulfilled]: (state, { payload: { data } }) => {
      state.total = data.total,
      state.subTotal = data.sub_total,
      state.dataChart = serializeData(data.chart_data);
    },
  },
});

export default slice.reducer;
