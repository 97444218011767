import { Table, Button, Modal, PageHeader, Form, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomTable from '../../components/CustomTable';
import {
  getList,
  deleteCategory,
  createCategory,
  getDetailCategory,
  updateCategory,
  resetStatus
} from "../../store/reducers/category_notification";
import CategoryNotificationModalCreate from "../../components/CategoryNotificationModalCreate";

const CategoryNotification = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    data,
    isLoading,
    isLoadingDetail,
    isLoadingCreate,
    isLoadingUpdate,
    error,
    errorCreate,
    errorUpdate,
    successCreate,
    successDelete,
    successUpdate,
    detail,
  } = useSelector((state) => state.categoryNotification);
  const [dataDelete, setDataDelete] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onFinishForm = (values) => {
    if (isEdit) {
      dispatch(updateCategory({ idCategory: detail._id, dataPut: { ...values } })).then(
        unwrapResult
      );
    } else {
      dispatch(createCategory({ ...values })).then(unwrapResult);
    }
  };

  useEffect(() => {
    if (error) {
      notification.error({ message: t("common.title-error"), description: t("common.description-error") });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getList());
    return function cleanup() {
      dispatch(resetStatus());
    };
  }, []);

  useEffect(() => {
    if (successDelete) {
      notification.success({ message: t("common.title-success"), description: t("category-notification.delete-success") });
      dispatch(getList());
    }
  }, [successDelete]);

  useEffect(() => {
    if (successCreate) {
      notification.success({ message: t("common.title-success"), description: t("category-notification.create-success") });
      form.resetFields();
      setVisibleModal(false);
      dispatch(getList());
    }
  }, [successCreate]);

  useEffect(() => {
    if (successUpdate) {
      notification.success({ message: t("common.title-success"), description: t("category-notification.update-success") });
      form.resetFields();
      setIsEdit(false);
      setVisibleModal(false);
      dispatch(getList());
    }
  }, [successUpdate]);

  useEffect(() => {
    if (detail._id) {
      form.setFieldsValue({ ...detail });
    } else {
      form.resetFields();
    }
  }, [detail]);

  const handleClickDelete = (e) => {
    setIsModalVisible(true);
    setDataDelete(e._id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    dispatch(deleteCategory(dataDelete));
    setDataDelete(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDataDelete(null);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setIsEdit(false);
    form.resetFields();
  };

  return (
    <>
      <Modal
        onCancel={() => {
          setVisibleModal(false);
          setIsEdit(false);
          form.resetFields();
        }}
        className="modal-user"
        visible={visibleModal}
        footer={null}
        title={isEdit ? t("category-notification.update") : t("category-notification.create")}
        forceRender
        width="720px"
      >
        <Form layout="vertical" form={form} name="modal-create-category-notification" onFinish={onFinishForm} initialValues={{
          name: null
        }}>
          <CategoryNotificationModalCreate />
          <div className="employee-modal__footer-create">
            <div className="employee-modal__footer--action">
              <Button htmlType="button" onClick={() => closeModal()} className="employee-modal__footer--button">
                {t("employee.cancel")}
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
                {t("employee.save")}
              </Button>
            </div>

          </div>
        </Form>
      </Modal>
      <div className="page-empolyee">
        <PageHeader
          className="page-title"
          title={t('category-notification.title')}
          extra={
            [
              <Button
                key={1}
                type="primary"
                htmlType="button"
                onClick={() => {
                  setVisibleModal(true);
                  setIsEdit(false);
                }}>
                {t('category-notification.add-category-notification')}
              </Button>

            ]
          }
        />
        <div className="page-content">
          <div className="table-list">
            <CustomTable data={data} loading={isLoading} rowselect={false}>
              <Table.Column
                align="center"
                title={t('category-notification.name-category-notification')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <span className="table-list__row--checktitle">
                      { e.name}
                    </span>
                  );
                }}
              />
              <Table.Column
                align="center"
                title={t('employee.action')}
                className="table-list__row"
                render={(e) => {
                  return (
                    <div className="action">
                      <a onClick={() => {
                        setVisibleModal(true);
                        setIsEdit(true);
                        dispatch(getDetailCategory({ _id: e._id, name: e.name }));
                      }} className="action__delete">
                        <EditOutlined className="action__delete__icon" />
                        <span className="action__delete__titledelete">{t("employee.edit")}</span>
                      </a>
                      <a className="action__delete" onClick={() => handleClickDelete(e)}>
                        <DeleteOutlined className="action__delete__icon" />
                        <span className="action__delete__titledelete">{t("employee.delete")}</span>
                      </a>
                    </div>
                  );
                }}
              />
            </CustomTable>
          </div>
          <Modal centered title={t('employee.modal-notifycation')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <h3>{t('category-notification.are-you-sure-you-want-to-change-the-delete')}</h3>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CategoryNotification;
