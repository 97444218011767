import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Upload, message, notification, Spin, Radio } from "antd";
import { useTranslation } from "react-i18next";
import './index.scss';
import { useHistory } from 'react-router-dom';
import UpdateNew from '../../components/UpdateNews';
import { getListTags } from "../../store/reducers/news";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { clearDataUpdate, getApiNewsDetail, updateNewDetail, getApiListTags, getAuths, getNewsCategories } from "../../store/reducers/listnews";
const UpdateNews = () => {
    const params = {
        "keyword": "",
        "tag_type": 0,
        "page": 1,
        "per_page": 10
    };
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { error, success, isLoading, datadetail } = useSelector((state) => state.listnews);
    const [avatar, setAvatar] = useState(datadetail.thumbs);
    const [current, setCurrent] = useState(1);
    const [errorImage, setErrorImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [dataTags, setDataTags] = useState([]);
    const [dateDisplay, setDateDisplay] = useState(false);
    const [userType, setUserType] = useState(1);
    const [titlePreview, setTitlePreview] = useState("");
    const [summaryPreview, setSummaryPreview] = useState("");
    const [linkNews, setLinkNews] = useState("");
    const [valueDate, setValueDate] = useState({});
    const handleChangeAvatar = useCallback(
        (info) => {
            if (info.file.status === "uploading") {
                setLoadingAvatar(true);
                return;
            }
            if (info.file.status === "done") {
                setLoadingAvatar(false);
                setAvatar(`${info.file.response.path}`);
            }
        },
        [loadingAvatar]
    );
    const beforeUpload = (file) => {
        setErrorImage(null);
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            setErrorImage(t("update-news.extension-avatar"));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setErrorImage(t("update-news.limit-size-avatar"));
        }
        return isJpgOrPng && isLt2M;
    };
    const onFinishForm = (values) => {
        dispatch(updateNewDetail(
            {
                ...values,
                id: datadetail._id,
                thumbs: avatar ? avatar : datadetail.thumbs,
                tags:values.tags?values.tags:[],
                public_date: values.public_date?._d ? moment(new Date(values.public_date._d)).format("YYYY-MM-DD") : "",
            }
        ));
    };
    const onChangeTextEditor = (event, editor) => form.setFieldsValue({ content: editor.getData() });
    
    useEffect(() => {
        if (error) {
            notification["error"]({
                message: t("common.title-error"),
                description: t("common.description-error"),
            });
        }
    }, [error]);

    useEffect(() => {
        if (success) {
            notification["success"]({
                message: t("update-news.title-success"),
                description: t("update-news.description-success"),
            });
            form.resetFields();
            setAvatar("");
            setSummaryPreview("");
            setLinkNews("");
            setTitlePreview("");
            dispatch(clearDataUpdate());
            history.push('/list-news');
        }
    }, [success]);
    useEffect(() => {
        return () => {
            dispatch(clearDataUpdate());
        };
    }, []);
    const { id } = useParams();
    const handlCheckDate = () => {
        setDateDisplay(true);
    };

    useEffect(() => {
        dispatch(getApiNewsDetail({ id: id }));
        dispatch(getAuths());
        dispatch(getNewsCategories());
        dispatch(getListTags(params));
    }, []);

    const showModalTags = () => {
        dispatch(getListTags(params));
        setShowModal(true);
    };
    const handleCancelTags = () => {
        setShowModal(false);
    };

    const handleChange = (value) => {
        setDataTags(value);
    };
    const handleReset = () => {
        history.push('/list-news');
    };
    if (Object.keys(datadetail).length === 0 && datadetail.constructor === Object) {
        return <div className="loading">
            <Spin className="loading__spin" />
        </div>;
    }
    const onCurrentPage = (current) => {
        setCurrent(current);
        params['page'] = current;
        dispatch(getListTags(params));
    };
    return (
        <div className="create-news">
            <h2>{t("update-news.title")}</h2>
            <div>
                <Form
                    layout="vertical"
                    form={form}
                    onFieldsChange={(e) => {
                        setValueDate(form.getFieldValue("public_date"));
                    }}
                    initialValues={{
                        title: datadetail.title,
                        content: datadetail.content,
                        description: datadetail.description,
                        news_category_id: datadetail.news_category_id?._id,
                        thumbs: datadetail.thumbs,
                        for_user_type: datadetail.for_user_type,
                        tags: datadetail?.tags.map(e => e.name),
                        external_link: datadetail?.external_link,
                        source: datadetail?.source,
                        public_date:datadetail.public_date?moment(new Date(datadetail?.public_date)):moment(new Date()),
                        status: datadetail.status,
                    }}
                    onFinish={onFinishForm}
                    requiredMark={false}
                    name="create-news"
                >
                    <UpdateNew
                        form={form}
                        datadetail={datadetail}
                        isLoading={isLoading}
                        userType={userType}
                        setUserType={setUserType}
                        onChangeTextEditor={onChangeTextEditor}
                        resetForm={handleReset}
                        titlePreview={titlePreview}
                        setTitlePreview={(value) => setTitlePreview(value)}
                        summaryPreview={summaryPreview}
                        linkNews={linkNews}
                        setSummaryPreview={value => setSummaryPreview(value)}
                        setLinkNews={value => setLinkNews(value)}
                        showModalTags={showModalTags}
                        handleCancelTags={handleCancelTags}
                        handleChange={handleChange}
                        showModal={showModal}
                        setDateDisplay={(value) => setDateDisplay(value)}
                        dateDisplay={dateDisplay}
                        handlCheckDate={handlCheckDate}
                        valueDate={valueDate?._d}
                        onCurrentPage={onCurrentPage}
                        current={current}
                    >
                        <div className="create-news__box-white create-news__box-avatar">
                        <Form.Item
                            label={t("update-news.avatar")}
                            name="thumbs"
                            rules={[
                                {
                                    required: true, message: t("create-news.required-avatar"),
                                },
                                {
                                    validator: () => (errorImage ? Promise.reject(errorImage):Promise.resolve()),
                                 }
                            ]}
                        >
                            <Upload
                                name="image"
                                accept="image/*"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChangeAvatar}
                                action={`${process.env.REACT_APP_API_URL}/api/upload-image`}
                            >
                                <img
                                    src={avatar ? avatar : datadetail.thumbs}
                                    alt="avatar"
                                    className="create-news__preview"
                                />
                            </Upload>
                        </Form.Item>
                    </div>
                    </UpdateNew>
                </Form>
        </div>
        </div >
    );
};

export default UpdateNews;
