import { configureStore } from "@reduxjs/toolkit";
import user from "./reducers/user";
import auth from "./reducers/auth";
import news from "./reducers/news";
import broker from "./reducers/broker";
import listnews from "./reducers/listnews";
import listNotification from "./reducers/list_notification";
import notification from "./reducers/notification";
import employees from "./reducers/employee";
import categoryNews from './reducers/category_news';
import categoryNotification from './reducers/category_notification';
import home from "./reducers/home";

const store = configureStore({
  reducer: {
    user,
    auth,
    news,
    broker,
    listnews,
    listNotification,
    notification,
    employees,
    categoryNews,
    home,
    categoryNotification
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
