export const checkPermissions = (action) => {
    let user = localStorage.getItem('user');
    if (!user) {
        return false;
    }
    user  = JSON.parse(user);
    const userPermissions = user.roles;

    if (!user.access_limit) {
        return true;
    }

    return userPermissions.includes(action);
};