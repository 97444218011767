import { InfoCircleOutlined, Loading3QuartersOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Upload, Form, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getUploadAvarta, updateProfile } from '../../store/reducers/auth';
import UploadImage from '../UploadImage';
import './information.scss';
// eslint-disable-next-line no-useless-escape
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
const PHONE_VN_REGEX = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;

const Information = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { success } = useSelector(state => state.auth);
    const { user } = props;
    const [avartaUrl, setAvartaUrl] = useState('');
    const action = getUploadAvarta();

    const onChangeAvarta = (info) => {
        setAvartaUrl(info.path);
    };

    const onFinish = (values) => {
        console.log(values);
        values['avatar'] = avartaUrl;
        dispatch(updateProfile(values));
    };

    useEffect(() => {
        if (success) {
            notification["success"]({
                message: t("information.update-profile-title"),
                description: t("information.update-profile-success"),
            });
        }
    }, [success]);

    return (
        <div className="information">
            <Form
                layout="vertical"
                initialValues={user}
                onFinish={onFinish}
            >
                <div className="information__avarta">
                    <UploadImage uploadFile={onChangeAvarta} action={action} name="image" defaultImage={user?.avatar} />
                </div>
                <Row gutter={24}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={t('information.name')}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t("employee.account-error")
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={t('information.phone')}
                            name="phone"
                            rules={[
                                { required: true, message: t("employee.phone-error-null") },
                                { transform: (value) => value ? value.trim() : null, pattern: PHONE_VN_REGEX, message: t("employee.phone-error") },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={t('information.email')}
                            name="email"
                            rules={[
                                { transform: (value) => value ? value.trim() : null, type: "email", message: t("employee.email-error") },
                                { required: true, message: t("employee.email-error-null") }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={t('information.facebook')}
                            name="facebook"
                            rules={[
                                { transform: (value) => value ? value.trim() : null, pattern: URL_REGEX, message: t("employee.link-error") },
                                { required: true, message: t("employee.link-error-null") },
                            ]}
                        >
                            <Input value={user?.facebook} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label={t('information.address')}
                            name="address"

                        >
                            <Input value={user?.address} />
                        </Form.Item>
                    </Col>

                </Row>
                <Button type="primary" htmlType="submit">{t('information.update_profile')}</Button>
            </Form>
        </div>
    );
};

export default Information;
