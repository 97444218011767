import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectToPage = ({ to }) => {
  const history = useHistory();
  useEffect(() => {
    history.push(to);
  }, []);
  return <div />;
};

export default RedirectToPage;
