export const Permission = {
    CreateNews: 'create_news',
    UpdateNews: 'update_news',
    DeleteNews: 'delete_news',
    ListNews: 'list_news',
    CreateNotify: 'create_notify',
    ListNotify: 'list_notify',
    CreateEmployee: 'create_employee',
    UpdateEmployee: 'update_employee',
    DeleteEmployee: 'delete_employee',
    ListEmployee: 'list_employee',
    ChangePass: 'change_pass',
    CreateNewCategory: 'create_new_category',
    UpdateNotify: 'update_notify',
    CreateNotifyCategory: 'create_notify_category'
};