import { HomeOutlined, PlusCircleOutlined, UsergroupDeleteOutlined, ProfileOutlined, PlusSquareOutlined, NotificationOutlined } from "@ant-design/icons";
import broker from "../assets/images/img-menu/broker.svg";
import createNews from '../assets/images/img-menu/create-new.svg';
import createUser from '../assets/images/img-menu/userlist.svg';
import home from '../assets/images/img-menu/home.png';
import listNews from '../assets/images/img-menu/listnews.svg';
import notification from '../assets/images/img-menu/notification.svg';
import notificationactive from '../assets/images/img-menu/notification-active.svg';
import brokeractive from '../assets/images/img-menu/broker_active.svg';
import createnewsactive from '../assets/images/img-menu/create-new-active.svg';
import listuseractive from '../assets/images/img-menu/userlist-active.svg';
import listnewactive from '../assets/images/img-menu/listnew-active.svg';
import {
  BellOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
const PATH_MENU = {
  HOME: "/",
  PAGE1: "/page1",
  ListUser: "/users",
  BROKER: "/broker",
  CREATE_NEWS: "/create-news",
  LISTNEWS: '/list-news',
  LIST_NOTIFICATION: '/notifications',
  CREATE_NOTIFICATION: "/notifications",
  EMPLOYEE: "/employees",
  CATEGORY_NEWS: "/category-news",
  CATEGORY_NOTIFY: "/category-notification",
  SMS_REPORT: "/sms-report"
};

const LIST_MENU = [
  {
    path: PATH_MENU.CREATE_NEWS,
    name: "create-news",
    action: 'create_news',
    icon: <img src={createNews}></img>,
    icon1: <img src={createnewsactive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.LISTNEWS,
    name: "list-news",
    action: "list_news",
    icon: <img src={listNews}></img>,
    icon1: <img src={listnewactive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.ListUser,
    name: "users",
    icon: <img src={createUser}></img>,
    icon1: <img src={listuseractive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.BROKER,
    name: "broker",
    icon: <img src={broker}></img>,
    icon1: <img src={brokeractive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.CREATE_NOTIFICATION,
    name: "list-notification",
    icon: <img src={notification}></img>,
    icon1: <img src={notificationactive}></img>,
    children: [],
    action: 'list_notify'
  },
];

const SETTING_MENU = [
  {
    path: PATH_MENU.EMPLOYEE,
    name: "employee",
    action: 'list_employee',
    icon: <img src={createUser}></img>,
    icon1: <img src={listuseractive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.CATEGORY_NEWS,
    name: "category-news",
    action: 'create_new_category',
    icon: <img src={createUser}></img>,
    icon1: <img src={listuseractive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.CATEGORY_NOTIFY,
    name: "category-notification",
    action: 'create_notify_category',
    icon: <img src={createUser}></img>,
    icon1: <img src={listuseractive}></img>,
    children: [],
  },
  {
    path: PATH_MENU.SMS_REPORT,
    name: "sms-report",
    action: 'sms_report',
    icon: <img src={createUser}></img>,
    icon1: <img src={listuseractive}></img>,
    children: [],
  }
];

export { PATH_MENU, LIST_MENU, SETTING_MENU};
