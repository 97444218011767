import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import MyUploadAdapter from "./CustomAdapterUpload";

const TextEditor = ({ placeholder, onChangeTextEditor, datadetail, disabled = false }) => {
  return (
    <div>
      <CKEditor
        id="editor"
        editor={ClassicEditor}
        data={datadetail}
        config={{
          placeholder,
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        disabled={disabled}
        placeholder="create-news.description-category"
        onChange={onChangeTextEditor}
        onReady={(editor) => {
          if (editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
              return new MyUploadAdapter(loader);
            };
          }
        }}
      />
    </div>
  );
};

export default TextEditor;
