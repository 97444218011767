export const LOGIN_URL = "/api/auth/login";
export const PROFILE_URL = "/api/auth/profile";
export const SERCH_USER_URL = "/api/user/client";
export const CREATE_NEWS_URL = "/api/news/create";
export const UPDATE_STATUS_URL = "/api/user/update-status";
export const UPDATE_APPROVE_URL = "/api/user/update-approve";
export const SEARCH_LIST_NEWS_URL = "/api/news/list";
export const GET_LISTTAGS = "/api/user/tags";
export const SEARCH_BROKER_URL = "/api/user/broker";
export const LIST_EMPLOYEE_URL = "/api/employee/list";
export const CREATE_EMPLOYEE_URL = "/api/employee/create";
export const DELETE_EMPLOYEE_URL = "/api/employee/delete";
export const UPDATE_EMPLOYEE_URL = "/api/employee/update";
export const DETAIL_EMPLOYEE_URL = "/api/employee/detail";
export const ROLES_EMPLOYEE_URL = "/api/employee/list-role";
export const UPDATE_STATUS = "/api/user/update-status";
export const GET_SEARCH_LIST_NOTIFICATION_URL = "api/notifications/list";
export const UPDATE_STATUS_NOTIFICATIONS_URL = "/api/notifications/send";
export const CREATE_NOTIFICATION_URL = "/api/notifications/create";
export const CATEGORIES_NOTIFICATION_URL = "/api/notifications/list-category";
export const GET_AUTH_URL = "/api/employee/list-select";
export const GET_NEWS_CATEGORY_URL = "/api/news-category/list";
export const DETAIL_USER_URL = '/api/user/detail';
export const GET_API_NEWS_DETAIL = '/api/news';
export const UPDATE_API_NEWS_DETAIL = '/api/news/update';
export const DELETE_API_NEWS = 'api/news';
export const GET_API_LISTAGS = '/api/tags/list';
export const UPDATE_STATUS_NEWS = '/api/news/publish';
export const UPDATE_APPROVED_ID_CARD = '/api/user/approved-idcard';
export const UPDATE_APPROVED_PORTRAIT = '/api/user/approved-portrait';

// Category News
export const LIST_CATEGORY_NEWS_URL = "/api/news-category/list";
export const DELETE_CATEGORY_NEWS_URL = "/api/news-category";
export const CREATE_CATEGORY_NEWS_URL = "/api/news-category";
export const UPDATE_CATEGORY_NEWS_URL = "/api/news-category";


export const GET_DASHBOARD = "/api/analytic-home";

// Category Notification
export const LIST_CATEGORY_NOTIFICATION_URL = "/api/notifications/list-category";
export const DELETE_CATEGORY_NOTIFICATION_URL = "/api/notifications/category";
export const CREATE_CATEGORY_NOTIFICATION_URL = "/api/notifications/category";
export const UPDATE_CATEGORY_NOTIFICATION_URL = "/api/notifications/category";


export const UPLOAD_TMP_FILE = '/api/upload-image';
export const UPDATE_PROFILE = '/api/employee/update-profile';
export const UPDATE_PASSWORD = '/api/employee/update-password';

export const SETTING = '/api/setting';
