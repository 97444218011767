import React, { useEffect, useState } from 'react';
import { Statistic, Row, Col, PageHeader, Card } from 'antd';
import "./index.scss";
import { Content } from "antd/lib/layout/layout";
import { SETTING } from '../../constants/endpoint';
import { callAuthorizationApi } from "../../apis/axiosClient";
import { useTranslation } from "react-i18next";

const SmsReport = () => {
    const { t } = useTranslation();

    const [dataSetting, setDataSetting] = useState({});

    useEffect(() => {
        getSetting();
    }, []);

    const getSetting = async () => {
        try {
            const response = await callAuthorizationApi(SETTING, 'GET', {});
            setDataSetting(response?.data);
        } catch (err) {
            console.log(err);
        }
    };

    console.log(dataSetting);

    return (
        <div className="sms-report">
            <PageHeader className="page-title" title={t("sms_report.title")} />
            <Content className="page-content">
                <Card bordered={false}>
                    <div className="report-row">
                        <div className="report-col">
                            <p className="report-col__title">{t('sms_report.total')}</p>
                            <p className="report-col__content ">{dataSetting?.count_otp_send_failed + dataSetting?.count_otp_send_success}</p>

                        </div>
                        <div className="report-col">
                            <p className="report-col__title">{t('sms_report.total_failed')}</p>
                            <p className="report-col__content report-col__content--sent">{dataSetting?.count_otp_send_failed}</p>
                        </div>
                        <div className="report-col">
                            <p className="report-col__title">{t('sms_report.total_success')}</p>
                            <p className="report-col__content report-col__content--success">{dataSetting?.count_otp_send_success}</p>
                        </div>
                    </div>
                </Card>
            </Content>

        </div>
    );
};

export default SmsReport;
