import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  LIST_EMPLOYEE_URL,
  CREATE_EMPLOYEE_URL,
  DELETE_EMPLOYEE_URL,
  DETAIL_EMPLOYEE_URL,
  ROLES_EMPLOYEE_URL,
  UPDATE_EMPLOYEE_URL,
  UPDATE_PROFILE,
} from "../../constants/endpoint";
import { callAuthorizationApi } from "../../apis/axiosClient";

const initialState = {
  isLoadingDetail: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  isLoadingUpdate: false,
  isLoading: false,
  status: "idle",
  data: [],
  listRole: [],
  error: false,
  errorCreate: false,
  errorUpdate: false,
  success: false,
  successCreate: false,
  successDelete: false,
  successUpdate: false,
  total: 0,
  per_page: 0,
  detail: {},
};

export const getListRole = createAsyncThunk("employees/list-role", async (_, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(ROLES_EMPLOYEE_URL);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateEmployee = createAsyncThunk(
  "employee/update",
  async ({ idEmployee, dataPut }, { rejectWithValue }) => {
    try {
      const response = await callAuthorizationApi(`${UPDATE_EMPLOYEE_URL}/${idEmployee}`, "PUT", dataPut);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDetailEmployee = createAsyncThunk("employee/detail", async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(`${DETAIL_EMPLOYEE_URL}/${idEmployee}`);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createEmployee = createAsyncThunk("employees/create", async (dataPost, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(CREATE_EMPLOYEE_URL, "POST", dataPost);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteEmployee = createAsyncThunk("employees/delete", async (idEmployee, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(`${DELETE_EMPLOYEE_URL}/${idEmployee}`, "DELETE");
    return { ...response, _id: idEmployee };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getListEmployee = createAsyncThunk("employees/getList", async (params, { rejectWithValue }) => {
  try {
    const response = await callAuthorizationApi(LIST_EMPLOYEE_URL, "GET", {}, params);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const slice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    removeDetailEmployee: (state) => {
      state.detail = {};
    },
    resetStatus: (state) => {
      state.successCreate = false;
      state.successDelete = false;
      state.successUpdate = false;
    },
  },
  extraReducers: {
    [getListEmployee.pending]: (state) => {
      state.isLoading = true;
      state.error = false;
      state.success = false;
    },
    [getListEmployee.fulfilled]: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.error = false;
      state.success = true;
      state.data = data.data;
      state.total = data.total;
      state.perPage=data.perPage;
    },
    [getListEmployee.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
      state.success = false;
    },
    [createEmployee.pending]: (state) => {
      state.isLoadingCreate = true;
      state.errorCreate = false;
      state.error = false;
      state.successCreate = false;
    },
    [createEmployee.fulfilled]: (state) => {
      state.isLoadingCreate = false;
      state.errorCreate = false;
      state.error = false;
      state.successCreate = true;
    },
    [createEmployee.rejected]: (
      state,
      {
        payload: {
          response: { data },
        },
      }
    ) => {
      state.isLoadingCreate = false;
      state.error = true;
      state.successCreate = false;
      state.errorCreate = data.message === "EMPLOYEE_IS_EXIST";
    },
    [deleteEmployee.pending]: (state) => {
      state.isLoadingDelete = true;
      state.successDelete = false;
      state.error = false;
    },
    [deleteEmployee.fulfilled]: (state) => {
      state.successDelete = true;
      state.isLoadingDelete = false;
      state.error = false;
      // const newEmployees = state.data.filter((item) => item._id !== payload._id);
      // state.data = newEmployees;
    },
    [deleteEmployee.rejected]: (state) => {
      state.successDelete = false;
      state.error = true;
    },
    [getListRole.fulfilled]: (state, { payload: { data } }) => {
      state.listRole = data;
    },
    [getDetailEmployee.fulfilled]: (state, { payload: { data } }) => {
      state.detail = data;
    },
    [updateEmployee.pending]: (state) => {
      state.isLoadingUpdate = true;
      state.errorUpdate = false;
      state.error = false;
      state.successUpdate = false;
    },
    [updateEmployee.fulfilled]: (state) => {
      state.isLoadingUpdate = false;
      state.errorUpdate = false;
      state.error = false;
      state.successUpdate = true;
    },
    [updateEmployee.rejected]: (
      state,
      {
        payload: {
          response: { data },
        },
      }
    ) => {
      // console.log(payload);
      state.isLoadingUpdate = false;
      state.error = true;
      state.successUpdate = false;
      state.errorUpdate = data.message === "PHONE_IS_REGISTED" || data.message === "EMAIL_IS_REGISTED";
    },
  },
});

export const { removeDetailEmployee, resetStatus } = slice.actions;
export default slice.reducer;
