import React, { useState, useEffect } from 'react';
import { Table } from "antd";

const CustomTable = (props) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([0]);
    const { data, children, rowselect } = props;

    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    return (
        <Table
            rowSelection={rowselect?
                {
                    selectedRowKeys: selectedRowKeys,
                    onChange: (e, f) => {
                        var slections = f.map(e => e._id);
                        onSelectChange(slections);
                    }
                }:false
            }
            rowKey={"_id"}
            dataSource={data}
            pagination={false}
        >
            { children}
        </Table >
    );
};
export default CustomTable;