import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {UPDATE_STATUS_NEWS,DELETE_API_NEWS,GET_API_NEWS_DETAIL,UPDATE_API_NEWS_DETAIL, SEARCH_LIST_NEWS_URL, GET_AUTH_URL, GET_LISTTAGS, GET_NEWS_CATEGORY_URL, LIST_CATEGORY_NOTIFICATION_URL } from '../../constants/endpoint';
import {callAuthorizationApi } from "../../apis/axiosClient";


export const getAuths = createAsyncThunk("listnews/getAuths", async (_, { rejectWithValue }) => {
    try {
        const response = await callAuthorizationApi(GET_AUTH_URL);
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
});
export const getNewsCategories = createAsyncThunk("/api/news-category/list", async (_, { rejectWithValue }) => {
    try {
        const response = await callAuthorizationApi(GET_NEWS_CATEGORY_URL);
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
});
export const getNotificationCategories = createAsyncThunk("/api/notification-category/list", async (_, { rejectWithValue }) => {
    try {
        const response = await callAuthorizationApi(LIST_CATEGORY_NOTIFICATION_URL);
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
});
const slice = createSlice({
    name: 'listnews',
    initialState: {
        data: [],
        isLoading: true,
        total: 0,
        per_page: 0,
        listtags: [],
        auths: [],
        categories: [],
        notificationCategories: [],
        usertypes: [{value: 1, label: 'Người dùng'}, {value: 2, label: 'Nhà môi giới'}, {value: 3, label: 'Tất cả'}],
        datadetail:{},
        error: false,
        success: false,
    },
    reducers: {
        getListNewsSuccess: (state, action) => {
            state.data = action.payload.data.data;
            state.total = action.payload.data.total;
            state.per_page = action.payload.data.per_page;
            state.isLoading = false;
        },
        getListnewsError: (state) => {
            state.isLoading = false;
        },
        getApiListTagsSuccess: (state, action) => {
            state.listtags = action.payload.data;
            state.isLoading = false;
        },
        getApiListTagsError: (state) => {
            state.isLoading = false;
        },
        getApiNewsDetailSucces:(state,action)=>{
           state.datadetail=action.payload;
        },
    
        getApiNewsDetailError:(state)=>{
            state.isLoading=false;
        },
        updateNewsDetailSuccess:(state,action)=>{
            let index = state.data.findIndex((data) => data._id === action.payload._id);
            state.data[index] = action.payload;
            state.success=true;
            state.data=[...state.data];
        },
        updateNewsDetailError:(state)=>{
            state.error=true;
        },
        clearData:(state)=>{
            state.success=false;
            state.datadetail={};
        },
        updateNewsStatusSuccess:(state,action)=>{
            let index = state.data.findIndex((data) => data._id === action.payload.id);
            state.data[index].status = action.payload.status;
            state.data=[...state.data];
        },
        deleteNewsSuccess:(state,action)=>{
            let newData = state.data.filter((data) => data._id !== action.payload);
            state.data=newData;
        },
        deleteNewsError:(state,action)=>{

        }
    },
    extraReducers: {
        [getAuths.fulfilled]: (state, { payload: { data } }) => {
            const serializeData = data.map((item) => ({ value: item._id, label: item.name }));
            state.auths = serializeData;
        },

        [getNewsCategories.fulfilled]: (state, { payload: { data } }) => {
            const serializeData = data.map((item) => ({ value: item._id, label: item.name }));
            state.categories = serializeData;
        },

        [getNotificationCategories.fulfilled]: (state, { payload: { data } }) => {
            const serializeData = data.map((item) => ({ value: item._id, label: item.name }));
            state.notificationCategories = serializeData;
        },
    }
});

export default slice.reducer;

// Actions
const {deleteNewsSuccess,deleteNewsError,updateNewsStatusSuccess,updateNewsStattusError,clearData, updateNewsDetailSuccess,updateNewsDetailError,getApiNewsDetailSucces,getApiNewsDetailError,getListNewsSuccess, getListnewsError, getApiListTagsSuccess, getApiListTagsError } = slice.actions;
export const searchListNews = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(SEARCH_LIST_NEWS_URL, 'POST', params);
        return dispatch(getListNewsSuccess(response));
    } catch (e) {
        return dispatch(getListnewsError(e));
    }
};
export const getApiListTags = () => async dispatch => {
    try {
        const response = await callAuthorizationApi(GET_LISTTAGS, 'GET', {});
        return dispatch(getApiListTagsSuccess(response));
    } catch (e) {
        return dispatch(getApiListTagsError());
    }
};
export const getApiNewsDetail = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${GET_API_NEWS_DETAIL}/${params.id}`, 'GET', {});
      return dispatch(getApiNewsDetailSucces(response.data));
    } catch (e) {
        return dispatch(getApiNewsDetailError());
    }
};
export const updateNewDetail = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${UPDATE_API_NEWS_DETAIL}/${params.id}`, 'PUT',params);
      return dispatch(updateNewsDetailSuccess(response.data));
    } catch (e) {
        return dispatch(updateNewsDetailError());
    }
};
export const deleteNews = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${DELETE_API_NEWS}/${params.id}/remove`, 'DELETE',{});
      return dispatch(deleteNewsSuccess(params.id));
    } catch (e) {
        return dispatch(deleteNewsError(e));
    }
};
export const clearDataUpdate = () => async dispatch => {
      return dispatch(clearData());
};
export const updateStatus = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${UPDATE_STATUS_NEWS}/${params.id}`, 'PUT',{status:params.status});
      return dispatch(updateNewsStatusSuccess(params));
    } catch (e) {
        return ;
    }
};
