import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotAuthorized = () => {
    const { t } = useTranslation();
    return (
        <div className="page-403">
            <Result
                status="403"
                title="403"
                subTitle={t('page.not_authorized')}
            />
        </div>
    );
};

export default NotAuthorized;
