import React, { useState, useEffect } from 'react';
import { Tabs, Select, Input, Form, PageHeader, Button, Table, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import './index.scss';
import { getListTags } from "../../store/reducers/news";
import CustomPagination from '../../components/CustomPagination';
import CustomTable from '../../components/CustomTable';
import { useDispatch, useSelector } from "react-redux";
import { updateStatus, searchListNews, deleteNews, getAuths, getNewsCategories } from '../../store/reducers/listnews';
import '../../assets/styles/index.scss';
import img from '../../assets/images/list_news/img.png';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { checkPermissions } from '../../helpers/role';
import { Permission } from '../../helpers/permission';
import { check } from 'prettier';
import { Spin } from 'antd';
const ListNews = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const { data, total, per_page, auths, listtags, categories, usertypes, isLoading } = useSelector(state => state.listnews);
    const { dataListTags } = useSelector((state) => state.news);
    const [valuesSelect, setValuesSelect] = useState([]);
    const [current, setCurrent] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [perPage, setPerPage] = useState(20);
    const [status, setStatus] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataUpdateNews, setDataUpdateNews] = useState(null);
    const [deleteListNews, setDataDeleteListNews] = useState(null);
    const params = {
        "keyword": "",
        "user_type": 0,
        "page": 1,
        "per_page": 10,
        "status": 0,
        "tags": [],
        "news_category_id": "",
        "created_by": ""
    };
    const dataTags = {
        "keyword": "",
        "tag_type": 0,
        "page": 1,
        "per_page": 10
    };
    const onCurrentPage = (current) => {
        setCurrent(current);
        params['page'] = current;
        dispatch(searchListNews(params));
    };

    const onChangetabs = (e) => {
        setStatus(parseInt(e));
        params['status'] = parseInt(e);
        resetPage();
        getSearchApi(params);
    };

    const resetPage = () => {
        setCurrent(1);
        params['page'] = 1;
    };

    const getSearchApi = (params) => {
        dispatch(searchListNews(params));
    };

    const onSearchKeyword = (e) => {
        setKeyword(e);
        params['keyword'] = e;
        getSearchApi(params);
        resetPage();
    };

    const handleClickDelete = (e) => {

        var params = {
            id: e._id,
        };
        setDataDeleteListNews(params);
        setIsModalVisible(true);
    };

    const handleClickStatus = (e) => {
        if (e.status === 2) {
            const paramsUpdate = {
                id: e._id,
                status: 1
            };
            setIsModalVisible(true);
            setDataUpdateNews(paramsUpdate);
        }
    };

    const handleValueSelect = (e) => {
        params['tags'] = e;
        dispatch(searchListNews(params));
    };

    const handleOk = () => {
        if (dataUpdateNews) {
            dispatch(updateStatus(dataUpdateNews));
        } else {
            dispatch(deleteNews(deleteListNews));
        }
        setIsModalVisible(false);
        setDataUpdateNews(null);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setDataUpdateNews(null);
    };

    const onChangeUrl = () => {
        history.push('/create-news');
    };

    useEffect(() => {
        dispatch(searchListNews(params));
        dispatch(getAuths());
        // dispatch(getApiListTags());
        dispatch(getListTags(dataTags));
        dispatch(getNewsCategories());
    }, [dispatch]);

    const handleSelectAuth = (value) => {
        if (value === undefined) {
            params['created_by'] = '';
            getSearchApi(params);
        } else {
            params['created_by'] = value;
            getSearchApi(params);
        }
    };

    const handleSelectCategory = (value) => {
        if (value === undefined) {
            params['news_category_id'] = '';
            getSearchApi(params);
        } else {
            params['news_category_id'] = value;
            getSearchApi(params);
        }
    };

    const handleSelectUserType = (value) => {
        if (value === undefined) {
            params['user_type'] = '';
            getSearchApi(params);
        } else {
            params['user_type'] = value;
            getSearchApi(params);
        }
    };
    const handleUpdateNews = (e) => {
        history.push(`/UpdateNews/${e._id}`);
    };
    if(isLoading) {
        return <Spin/>;
    }
    return <div className="page-news">
        <PageHeader
            className="page-title"
            title={t('list-notification.title')}
            extra={
                [
                    checkPermissions(Permission.CreateNews) && <Button key={1} type="primary" className="page-news__page-title" onClick={() => onChangeUrl()}>{t('news.create-post')}</Button>

                ]
            }
        />
        <div className="page-content">
            <div className="tabs">
                <Tabs
                    defaultActiveKey="0"
                    className="tabs"
                    onChange={(e) => onChangetabs(e)}
                >
                    <Tabs.TabPane
                        tab={t('news.tab.all-articles')}
                        key="0"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('news.tab.Not-posted-yet')}
                        key="2"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('news.tab.posted')}
                        key="1"
                    >
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div className="form-search">
                <Form
                    onFieldsChange={() => {
                        setValuesSelect(form.getFieldValue("tag"));
                        // setAuthor(form.getFieldValue("author"));
                    }}
                    form={form}
                    name="control-hooks"
                    className="form-search__form"
                >
                    <Input.Group compact>
                        <Form.Item name="tag">
                            <Select className='form-search__tag-select'
                                mode="multiple"
                                placeholder={t('news.form.Tagged-with')}
                                showArrow
                                onChange={(e) => handleValueSelect(e)}
                                autoFocus={false}
                            >
                                {dataListTags?.length ? dataListTags.map(item => (
                                    <Select.Option key={item.name}>{item.name}</Select.Option>
                                )) : null};
                            </Select>

                        </Form.Item>
                        <Form.Item name="author">
                            <Select className='form-search__tag-select'
                                showArrow
                                allowClear
                                placeholder={t('news.form.author')}
                                onChange={handleSelectAuth}
                                autoFocus={false}
                            >
                                <Select.Option key="">{t('news.form.author')}</Select.Option>
                                {auths.length ? auths.map(item => (
                                    <Select.Option key={item.value}>{item.label}</Select.Option>
                                )) : null};
                            </Select>

                        </Form.Item>
                        <Form.Item name="category">
                            <Select className='form-search__tag-select'
                                placeholder={t('news.form.category')}
                                showArrow
                                allowClear
                                onChange={handleSelectCategory}
                                autoFocus={false}
                            >
                                <Select.Option key=""></Select.Option>
                                {categories.length ? categories.map(item => (
                                    <Select.Option key={item.value}>{item.label}</Select.Option>
                                )) : null};
                            </Select>
                        </Form.Item>
                        {/* <Form.Item name="object">
                            <Select className='form-search__tag-select'
                                placeholder={t('news.form.object')}
                                showArrow
                                allowClear
                                onChange={e => handleSelectUserType(e)}
                                autoFocus={false}
                            >
                                <Select.Option key=""></Select.Option>
                                {usertypes.length ? usertypes.map(item => (
                                    <Select.Option key={item.value}>{item.label}</Select.Option>
                                )) : null};
                            </Select>

                        </Form.Item> */}
                        <Form.Item
                            className='form-search__input-search'
                            name="keySearch"
                        >
                            <Input.Search autoFocus={false} onSearch={(e) => onSearchKeyword(e)} allowClear placeholder={t('news.form.search-for-articles')} />
                        </Form.Item >
                    </Input.Group>
                </Form>
                <div className="form-search__list-tag-select">
                    {form.getFieldValue("tag") ? valuesSelect.map(valueSelect => {
                        return (
                            <span
                                className="form-search__list-tag-select__item"
                                key={valueSelect}>
                                {valueSelect}

                                <CloseOutlined
                                    className="form-search__list-tag-select__iconclose"
                                    onClick={() => {
                                        const newSelectValues = valuesSelect.filter(e => e !== valueSelect);
                                        setValuesSelect(newSelectValues);
                                        form.setFieldsValue({ tag: newSelectValues });
                                        if (newSelectValues.length === 0) {
                                            params['tags'] = [];
                                            dispatch(searchListNews(params));
                                        }
                                    }}
                                />
                            </span>
                        );
                    }) : null}
                </div>
            </div>
            <div className="table-list">
                <CustomTable data={data} rowselect={true}>
                    <Table.Column
                        title={t('news.column.title')}
                        className="table-list__row--title"
                        render={(e) => {
                            return (
                                <div className="image-title">
                                    <img src={e.thumbs} className='image-title__thumbs'></img>
                                    <a href="#" className='image-title__title'>{e.title}</a>
                                </div>
                            );
                        }}
                    />
                    <Table.Column
                        align="center"
                        title={t('news.column.view')}
                        className="table-list__row--number"
                        render={(e) => {
                            return (
                                <span className="table-list__row--center">
                                    { e.num_view}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('news.column.category')}
                        align='center'
                        className="table-list__row"
                        render={(e) => {
                            return (
                                <span className="table-list__row--column">
                                    { e?.news_category_id?.name}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('news.column.author')}
                        align='center'
                        className="table-list__row"
                        render={(e) => {
                            return (
                                <span className="table-list__row--column">
                                    { e?.created_by?.name}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('news.column.date-update')}
                        align='center'
                        className="table-list__row"
                        render={(e) => {
                            return (
                                <span className="table-list__row--column">
                                    { moment(e.updated_at).format('DD/MM/YYYY')}
                                    <br />
                                    { moment(e.updated_at).format('H:mm')}
                                </span>
                            );
                        }}
                    />
                    {(checkPermissions(Permission.UpdateNews) || checkPermissions(Permission.DeleteNews)) &&
                        <Table.Column
                            title={t('news.column.action')}
                            className="table-list__row "
                            align='center'
                            render={(e) => {
                                if (e.status === 1) {
                                    return (
                                        <div className="action">
                                            {
                                                checkPermissions(Permission.DeleteNews) && <a onClick={() => handleClickDelete(e)} className="action__delete">
                                                    <CloseOutlined className="action__delete__icon" />
                                                    <span className="action__delete__titledelete">{t('action.delete')}</span>
                                                </a>
                                            }
                                            { checkPermissions(Permission.UpdateNews) &&
                                                <a className="action__post action__post--inactive" onClick={() => handleClickStatus(e)}>
                                                    <CheckOutlined />
                                                    <span className={"action__post--inactive"}>{t('action.posted')}</span>
                                                </a>
                                            }
                                            { checkPermissions(Permission.UpdateNews) &&
                                                <a onClick={() => handleUpdateNews(e)} className="action__update">
                                                    <EditOutlined className="action__delete__icon" />
                                                    <span className="action__delete__titledelete">{t('action.update')}</span>
                                                </a>
                                            }
                                        </div>
                                    );

                                }
                                else {
                                    return (
                                        <div className="action">
                                            {
                                                checkPermissions(Permission.DeleteNews) && <a onClick={() => handleClickDelete(e)} className="action__delete">
                                                    <CloseOutlined className="action__delete__icon" />
                                                    <span className="action__delete__titledelete">{t('action.delete')}</span>
                                                </a>
                                            }
                                            { checkPermissions(Permission.UpdateNews) &&

                                                <a onClick={() => handleClickStatus(e)} className="action__post action__post--active">
                                                    <CheckOutlined />
                                                    <span className={"action__post--active"}>{t('action.post')}</span>
                                                </a>
                                            }
                                            { checkPermissions(Permission.UpdateNews) &&
                                                <a onClick={() => handleUpdateNews(e)} className="action__update">
                                                    <EditOutlined className="action__delete__icon" />
                                                    <span className="action__delete__titledelete">{t('action.update')}</span>
                                                </a>
                                            }

                                        </div>
                                    );
                                }
                            }}
                        />
                    }
                </CustomTable>
                <CustomPagination total={total} current={current} perPage={per_page} pageSize={per_page || 10} onChange={(e) => onCurrentPage(e)} />
            </div>
            <Modal centered title={t('users.modal.notifycation')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <h3>{!dataUpdateNews ? t('news.modal.are-you-sure-you-want-to-change-the-delete') : t('news.modal.are-you-sure-to-change-the-status')}</h3>
            </Modal>
        </div>
    </div >;
};
export default ListNews;