import { createSlice } from '@reduxjs/toolkit';
import { GET_SEARCH_LIST_NOTIFICATION_URL, UPDATE_STATUS_NOTIFICATIONS_URL,GET_LISTTAGS } from '../../constants/endpoint';
import { callApi, callAuthorizationApi } from "../../apis/axiosClient";

const slice = createSlice({
    name: 'list_notification',
    initialState: {
        data: [],
        isLoading: true,
        total: 0,
        perPage: 0,
        listtags: [],
    },
    reducers: {
        getListNotificationSuccess: (state, action) => {
            state.data = action.payload.data.data;
            state.total = action.payload.data.total;
            state.per_page = action.payload.data.per_page;
            state.isLoading = false;
        },
        getListNotificationError: (state, action) => {
            state.isLoading = false;
        },
        updateStatusNotificationsSuccess: (state, action) => {
            const index = state.data.findIndex((data) => data._id === action.payload.id);
            state.data[index].status = action.payload.status;
            state.data = [...state.data];
            state.isLoading = false;
        },
        updateStatusNotificationsError: (state, action) => {
            state.isLoading = false;
        },
        getApiListTagsSuccess: (state, action) => {
            state.listtags = action.payload.data;
            state.isLoading = false;
        },
        getApiListTagsError: (state, action) => {
            state.isLoading = false;
        },
    },
});

export default slice.reducer;

// Actions

const { getListNotificationSuccess, getListNotificationError,updateStatusNotificationsSuccess,updateStatusNotificationsError,getApiListTagsSuccess,getApiListTagsError} = slice.actions;

export const searchListNotification = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(GET_SEARCH_LIST_NOTIFICATION_URL, 'POST', params);
        return dispatch(getListNotificationSuccess(response));
    } catch (e) {
        return dispatch(getListNotificationError(e));
    }
};

export const onChangeNotificationsStatus = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${UPDATE_STATUS_NOTIFICATIONS_URL}/${params.id}`, 'PUT', { 'status': params.status });
        return dispatch(updateStatusNotificationsSuccess(params));
    } catch (e) {
        return dispatch(updateStatusNotificationsError());
    }
};

export const getApiBrokerListTags = () => async dispatch => {
    try {
        const response = await callAuthorizationApi(GET_LISTTAGS, 'GET', {});
        return dispatch(getApiListTagsSuccess(response));
    } catch (e) {
        return dispatch(getApiListTagsError());
    }
};
