import React, { useState, useEffect } from 'react';
import { Tabs, Select, Input, Form, PageHeader, Space, Table, Modal } from 'antd';
import './index.scss';
import '../../assets/styles/index.scss';
import { getListTags } from "../../store/reducers/news";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import CustomTable from '../../components/CustomTable';
import CustomPagination from '../../components/CustomPagination';
import { getUserDetail } from '../../store/reducers/user';
import moment from 'moment';
import BrokerDeatail from '../../components/BrokerDetail';
import { searchBroker, onChangeBrokerStatus, onChangeBrokerApprove, getApiBrokerListTags } from '../../store/reducers/broker';
const Broker = () => {

    const { t } = useTranslation();

    const { dataListTags } = useSelector((state) => state.news);

    const dispatch = useDispatch();

    const [valuesSelect, setValuesSelect] = useState([]);

    const [valuesInput, setValuesInput] = useState(null);

    const [dataUpdateUser, setDataUpdateUser] = useState(null);

    const [dataUpdateApprove, setDataUpdateApprove] = useState(null);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [form] = Form.useForm();

    const { data, total, per_page, listtags } = useSelector(state => state.broker);

    const [isShowDetail, setIsShowDetai] = useState(false);

    const [keyword, setKeyword] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [current, setCurrent] = useState(1);
    const [status, setStatus] = useState(0);
    const [approveStatus, setApproveStatus] = useState(3);
    const params = {
        "page": current,
        "per_page": perPage,
        "keyword": keyword,
        "status": status,
        "approve_status": approveStatus,
        "tags": [],
    };
    const dataTags = {
        "keyword": "",
        "tag_type": 0,
        "page": 1,
        "per_page": 10
    };
    useEffect(() => {
        dispatch(searchBroker(params));
        dispatch(getListTags(dataTags));
    }, [dispatch]);

    const onCurrentPage = (current) => {
        setCurrent(current);
        params['page'] = current;
        getSearchApi(params);
    };

    const onChangetabs = (e) => {
        if (parseInt(e) === 0) {
            params['status'] = parseInt(0);
            params['approve_status'] = parseInt(3);
            dispatch(searchBroker(params));
        } else
            if (parseInt(e) === 1) {
                params['approve_status'] = parseInt(1);
                resetPage();
                getSearchApi(params);
            } else if (parseInt(e) === 2) {
                params['approve_status'] = parseInt(2);
                resetPage();
                getSearchApi(params);
            }
            else if (parseInt(e) === 3) {
                params['status'] = parseInt(1);
                resetPage();
                getSearchApi(params);
            } else if (parseInt(e) === 4) {
                params['status'] = parseInt(2);
                resetPage();
                getSearchApi(params);
            }
    };

    const getSearchApi = (params) => {
        dispatch(searchBroker(params));
    };

    const resetPage = () => {
        setCurrent(1);
        params['page'] = 1;
    };

    const handleValueSelect = (e) => {
        params['tags'] = e;
        dispatch(searchBroker(params));
    };

    const onSearchKeyword = (e) => {
        setKeyword(e.trim());
        params['keyword'] = e.trim();
        resetPage();
        getSearchApi(params);
    };

    const handleClickApprove = (value) => {
        setIsModalVisible(true);
        var params = {
            id: null,
            approve_status: 0
        };
        if (value.approve_status === 1) {
            params = {
                id: value._id,
                approve_status: 0
            };
        } else {
            params = {
                id: value._id,
                approve_status: 1
            };
        }
        setDataUpdateApprove(params);

    };

    const handleClickStatus = (value) => {
        setIsModalVisible(true);
        var params = {
            id: null,
            status: 0
        };
        if (value.status === 1) {
            params = {
                id: value._id,
                status: 2
            };
        } else {
            params = {
                id: value._id,
                status: 1
            };
        }
        setDataUpdateUser(params);

    };

    const handleOk = () => {
        if (dataUpdateUser != null) {
            dispatch(onChangeBrokerStatus(dataUpdateUser));
            setDataUpdateUser(null);
        } else {
            dispatch(onChangeBrokerApprove(dataUpdateApprove));
            setDataUpdateApprove(null);

        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setDataUpdateUser(null);
    };

    const handleCancelDetail = () => {
        setIsShowDetai(false);
    };

    const handleShowDetail = (value) => {
        dispatch(getUserDetail(value));
        setIsShowDetai(true);
    };

    return <div className="page-broker">
        <PageHeader
            className="page-title"
            title={t('broker.title')}
        />
        <div className="page-content">
            <div className="tabs">
                <Tabs
                    defaultActiveKey="0"
                    className="tabs"
                    onChange={(e) => onChangetabs(e)}
                >
                    <Tabs.TabPane
                        tab={t('broker.tab.all-subscriptions')}
                        key="0"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('broker.tab.approved')}
                        key="1"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('broker.tab.Unapproved')}
                        key="2"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('broker.tab.are-active')}
                        key="3"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('broker.tab.stop-active')}
                        key="4"
                    >
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div className="form-search">
                <Form
                    onFieldsChange={(e) => {
                        setValuesSelect(form.getFieldValue("tag"));
                        setValuesInput(form.getFieldValue("keySearch"));
                    }}
                    form={form}
                    name="control-hooks"
                    className="form-search__form"
                >
                    <Input.Group compact>
                        <Form.Item name="tag">
                            <Select className='form-search__tag-select'
                                mode="multiple"
                                placeholder={t('broker.formSearch.Tagged-with')}
                                showArrow
                                onChange={(e) => handleValueSelect(e)}
                            >
                                {dataListTags?.length ? dataListTags.map(item => (
                                    <Select.Option key={item.name}>{item.name}</Select.Option>
                                )) : null};
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className='form-search__input-search'
                            name="keySearch"
                        >
                            <Input.Search allowClear placeholder={t('broker.formSearch.Search-articles')} onSearch={(e) => onSearchKeyword(e)} />
                        </Form.Item >
                    </Input.Group>
                </Form>
                {form.getFieldValue("tag") ? <div className="form-search__list-tag-select">
                    {valuesSelect.map(valueSelect => {
                        return (
                            <span
                                className="form-search__list-tag-select__item"
                                key={valueSelect}>
                                {valueSelect}

                                <CloseOutlined
                                    className="form-search__list-tag-select__iconclose"
                                    onClick={() => {
                                        const newSelectValues = valuesSelect.filter(e => e !== valueSelect);
                                        setValuesSelect(newSelectValues);

                                        form.setFieldsValue({ tag: newSelectValues });
                                        if (newSelectValues.length === 0) {
                                            params['tags'] = [];
                                            dispatch(searchBroker(params));
                                        }
                                    }}
                                />
                            </span>
                        );
                    })}
                </div> : null}
            </div>
            <div className="table-list">
                <CustomTable data={data} rowselect={true}>
                    <Table.Column
                        title={t('broker.table-clumn.student-namea')}
                        className="table-list__row--title"
                        render={(e) => {
                            return (
                                <a className='table-list__row--checktitle' onClick={() => handleShowDetail(e._id)}>
                                    {e.name}
                                </a>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.phone')}
                        className="table-list__row"
                        align='center'
                        render={(e) => {
                            return (
                                <span className='table-list__row--phonenumber'>
                                    {e.phone}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.number-of-links')}
                        className="table-list__row"
                        align='center'
                        render={(e) => {
                            return (
                                <span className='table-list__row--column'>
                                    {e.num_connect}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.number-of-saved-articles')}
                        className="table-list__row"
                        align='center'
                        render={(e) => {
                            return (
                                <span className='table-list__row--column'>
                                    {e.num_save_news}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.date-register')}
                        className="table-list__row"
                        align='center'
                        render={(e) => {
                            return (
                                <span className='table-list__row--column'>
                                    {moment(e.regist_date).format('DD/MM/YYYY')}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.connector_type')}
                        className="table-list__row"
                        align='center'
                        render={(e) => {
                            if (e.connector_type === 1) {
                                return (
                                    <span className='table-list__row--column'>
                                        {t('broker.table-clumn.connector_type-status.buy_broker')}
                                    </span>
                                );
                            } else if (e.connector_type === 2) {
                                return (
                                    <span className='table-list__row--column'>
                                        {t('broker.table-clumn.connector_type-status.sell_broker')}
                                    </span>
                                );
                            } else {
                                return (
                                    <span className='table-list__row--column'>
                                        {t('broker.table-clumn.connector_type-status.all_brother')}
                                    </span>
                                );
                            }
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.file')}
                        className="table-list__row"
                        align='center'
                        render={e => {
                            if (e.approve_status === 1) {
                                return (
                                    <span onClick={() => handleClickApprove(e)}>
                                        <CheckOutlined className='table-list__row table-list__row--disable' />  <span className="table-list__row table-list__row--enable">{t('broker.tab.approved')}</span>
                                    </span>
                                );
                            }
                            return (
                                <span onClick={() => handleClickApprove(e)}>
                                    <CheckOutlined className='table-list__row table-list__row--enable' />  <span className="table-list__row table-list__row--enable">{t('broker.tab.Unapproved')}</span>
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('broker.table-clumn.status')}
                        className="table-list__row"
                        align='center'
                        render={e => {
                            if (e.status === 2) {
                                return (
                                    <span onClick={() => handleClickStatus(e)}>
                                        <CheckOutlined className='table-list__row table-list__row--disable' /> <span className="table-list__row table-list__row--enable">{t('broker.table-clumn.stop-working')}</span>
                                    </span>
                                );
                            }
                            return (
                                <span onClick={() => handleClickStatus(e)}>
                                    <CheckOutlined className='table-list__row table-list__row--enable' /> <span className="table-list__row table-list__row--enable">{t('broker.table-clumn.are-active')}</span>
                                </span>
                            );
                        }}
                    />
                </CustomTable>
                <CustomPagination total={total} current={current} perPage={per_page} pageSize={per_page || 10} onChange={(e) => onCurrentPage(e)} />
            </div>
            <Modal centered title={t('users.modal.notifycation')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <h3>{dataUpdateUser != null ? t('broker.modal.Are-you-sure-you-want-to-change-the-status') : t('broker.modal.Are-you-sure-to-change-the-profile')}</h3>
            </Modal>
            <Modal visible={isShowDetail} onCancel={handleCancelDetail} footer={null} className="modalbroker">
                <BrokerDeatail />
            </Modal>
        </div>

    </div>;
};
export default Broker;