import React, { useState, useEffect } from 'react';
import { Tabs, Select, Input, Form, PageHeader, Space, Table, Modal } from 'antd';
import './index.scss';
import '../../assets/styles/index.scss';
import {getListTags } from "../../store/reducers/news";
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { searchUser, getApiListTags, onChangeStatus, getUserDetail } from '../../store/reducers/user';
import CustomPagination from '../../components/CustomPagination';
import CustomTable from '../../components/CustomTable';
import { useTranslation } from 'react-i18next';
import UserDetail from '../../components/UserDetail';

const ListUser = () => {
    const { t } = useTranslation();

    const [valuesSelect, setValuesSelect] = useState([]);
    const { dataListTags } = useSelector((state) => state.news);

    const [valuesInput, setValuesInput] = useState(null);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [dataUpdateUser, setDataUpdateUser] = useState(null);

    const [form] = Form.useForm();

    const { data, total, per_page } = useSelector(state => state.user);

    const dataListtags = useSelector(state => state.user.listtags);

    const [keyword, setKeyword] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [current, setCurrent] = useState(1);
    const [status, setStatus] = useState(0);

    const [isShowDetail, setIsShowDetai] = useState(false);

    const params = {
        "page": current,
        "per_page": perPage,
        "keyword": keyword,
        "status": status,
        "tags": [],
    };
    const dataTags = {
        "keyword": "",
        "tag_type": 0,
        "page": 1,
        "per_page": 10
      };
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(searchUser(params));
        dispatch(getListTags(dataTags));
    }, [dispatch]);

    const onChangetabs = (e) => {
        setStatus(parseInt(e));
        params['status'] = parseInt(e);
        resetPage();
        getSearchApi(params);
    };

    const getSearchApi = (params) => {
        dispatch(searchUser(params));
    };

    const handleValueSelect = (e) => {
        params['tags'] = e;
        dispatch(searchUser(params));
    };

    const onCurrentPage = (current) => {
        setCurrent(current);
        params['page'] = current;
        getSearchApi(params);
    };

    const handleClick = (value) => {
        setIsModalVisible(true);
        const id = value.status;
        var params = {
            id: null,
            status: 0
        };
        if (value.status === 1) {
            params = {
                id: value._id,
                status: 2
            };
            setDataUpdateUser(params);
        } else {
            params = {
                id: value._id,
                status: 1
            };
            setDataUpdateUser(params);
        }
    };

    const onSearchKeyword = (e) => {
        setKeyword(e);
        params['keyword'] = e;
        resetPage();
        getSearchApi(params);
    };

    const resetPage = () => {
        setCurrent(1);
        params['page'] = 1;
    };

    const handleOk = () => {
        dispatch(onChangeStatus(dataUpdateUser));
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCancelDetail = () => {
        setIsShowDetai(false);
    };

    const handleShowDetail = (value) => {
        dispatch(getUserDetail(value));
        setIsShowDetai(true);
    };


    return <div className="page-user">
        <PageHeader
            className="page-title"
            title={t('users.title')}
        />
        <div className="page-content">
            <div className="tabs">
                <Tabs
                    defaultActiveKey="0"
                    className="tabs"
                    onChange={(e) => onChangetabs(e)}
                >
                    <Tabs.TabPane
                        tab={t('users.tab.all-subscriptions')}
                        key="0"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('users.tab.are-active')}
                        key="1"
                    >
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={t('users.tab.stop-active')}
                        key="2"
                    >
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div className="form-search">
                <Form
                    onFieldsChange={(e) => {
                        setValuesSelect(form.getFieldValue("tag"));
                        setValuesInput(form.getFieldValue("keySearch"));
                    }}
                    form={form}
                    name="control-hooks"
                    className="form-search__form"
                >
                    <Input.Group compact>
                        <Form.Item name="tag">
                            <Select className='form-search__tag-select'
                                mode="multiple"
                                placeholder={t('users.formSearch.Tagged-with')}
                                showArrow
                                onChange={(e) => handleValueSelect(e)}
                                autoFocus={false}
                            >
                                {dataListTags?.length ? dataListTags.map(item => (
                                    <Select.Option key={item.name}>{item.name}</Select.Option>
                                )) : null};
                                </Select>
                        </Form.Item>
                        <Form.Item
                            className='form-search__input-search'
                            name="keySearch"
                        >
                            <Input.Search autoFocus={false} onSearch={(e) => onSearchKeyword(e)} allowClear placeholder={t('users.formSearch.Search-articles')} />
                        </Form.Item >
                    </Input.Group>
                </Form>
                {form.getFieldValue("tag") ? <div className="form-search__list-tag-select">
                    {valuesSelect.map(valueSelect => {
                        return (
                            <span
                                className="form-search__list-tag-select__item"
                                key={valueSelect}>
                                {valueSelect}

                                <CloseOutlined
                                    className="form-search__list-tag-select__iconclose"
                                    onClick={() => {
                                        const newSelectValues = valuesSelect.filter(e => e !== valueSelect);
                                        setValuesSelect(newSelectValues);
                                        form.setFieldsValue({ tag: newSelectValues });
                                        if (newSelectValues.length === 0) {
                                            params['tags'] = [];
                                            dispatch(searchUser(params));
                                        }
                                    }}
                                />
                            </span>
                        );
                    })}
                </div> : null}
            </div>
            <div className="table-list">
                <CustomTable data={data} rowselect={true}>
                    <Table.Column
                        align='left'

                        className="table-list__row"
                        title={t('users.table-clumn.student-namea')}
                        render={(e) => {
                            return (
                                <a onClick={()=>handleShowDetail(e._id)} className='table-list__row--checktitle'>
                                    {e.name}
                                </a>
                            );
                        }}
                    />
                    <Table.Column
                        align='center'

                        className="table-list__row"
                        title={t('users.table-clumn.phone')}
                        render={(e) => {
                            return (
                                <span className='table-list__row--checktitle'>
                                    { e.phone}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('users.table-clumn.number-of-links')}
                        align='center'
                        className="table-list__row"
                        render={(e) => {
                            return (
                                <span className='table-list__row--column'>
                                    { e.num_connect}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        title={t('users.table-clumn.number-of-saved-articles')}
                        align='center'
                        className="table-list__row"
                        render={(e) => {
                            return (
                                <span className='table-list__row--column'>
                                    { e.num_save_news}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        align='center'
                        title={t('users.table-clumn.date-register')}
                        className="table-list__row"
                        render={(e) => {
                            return (
                                <span className='table-list__row--column'>
                                    { moment(e.regist_date).format('DD/MM/YYYY')}
                                </span>

                            );
                        }}
                    />
                    <Table.Column
                        align='center'
                        title={t('users.table-clumn.status')}
                        render={e => {
                            if (e.status === 2) {
                                return (
                                    <span onClick={() => handleClick(e)}>
                                        <CheckOutlined className='table-list__row table-list__row--disable' /> <span className="table-list__row table-list__row--enable">{t('users.table-clumn.stop-working')}</span>
                                    </span>
                                );
                            }
                            return (
                                <span onClick={() => handleClick(e)}>
                                    <CheckOutlined className='table-list__row table-list__row--enable' /> <span className="table-list__row table-list__row--enable">{t('users.table-clumn.are-active')}</span>
                                </span>
                            );
                        }}
                    />
                </CustomTable>
                <CustomPagination total={total} current={current} perPage={per_page} pageSize={per_page || 10} onChange={(e) => onCurrentPage(e)} />
            </div>
            <Modal centered title={t('users.modal.notifycation')} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <h3>{t('users.modal.Are-you-sure-you-want-to-change-the-status')}</h3>
            </Modal>
            <Modal visible={isShowDetail} onCancel={handleCancelDetail} footer={null} className="modal">
                <UserDetail/>
            </Modal>
        </div>

    </div>;
};

export default ListUser;
