import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOGIN_URL, PROFILE_URL, UPDATE_PASSWORD, UPDATE_PROFILE, UPLOAD_TMP_FILE } from '../../constants/endpoint';
import { callApi, callAuthorizationApi } from "../../apis/axiosClient";
// Slice

const initialUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

export const updateProfile = createAsyncThunk(
    "employee/update-profile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await callAuthorizationApi(UPDATE_PROFILE, "PUT", data);
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const updatePassword = createAsyncThunk(
    "employee/update-password",
    async (data, { rejectWithValue }) => {
        try {
            const response = await callAuthorizationApi(UPDATE_PASSWORD, "PUT", data);
            return response;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
const slice = createSlice({
    name: 'auth',
    initialState: {
        user: initialUser,
        isLoading: false,
        error: null,
        isAuthUser: !!localStorage.getItem("user"),
        success: false,
        updatePasswordSuccess: false,
        updatePasswordMessage: ''
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload.data;
            localStorage.setItem('user', JSON.stringify(action.payload.data));
        },
        logoutSuccess: (state, action) => {
            state.user = null;
            localStorage.removeItem('user');
        },

        loginError: (state, action) => {
            state.error = action.payload;
        },

        getProfileSuccess: (state, action) => {
            state.user = action.payload.data;
        }
    },
    extraReducers: {
        [updateProfile.pending]: (state) => {
            state.success = false;
        },
        [updateProfile.fulfilled]: (state, { payload: { data } }) => {
            state.user = data;
            state.success = true;
        },
        [updateProfile.rejected]: (
            state,
            {
                payload: {
                    response: { data },
                },
            }
        ) => {
            state.error = data.message;
        },

        [updatePassword.pending]: (state) => {
            state.updatePasswordStatus = false;
            state.updatePasswordMessage = '';
        },
        [updatePassword.fulfilled]: (state, { payload: { data } }) => {
            state.updatePasswordStatus = true;
            state.updatePasswordMessage = '';
        },
        [updatePassword.rejected]: (
            state,
            {
                payload: {
                    response: { data },
                },
            }
        ) => {
            state.updatePasswordMessage = data.message;
        },
    }
});
export const isAuthSelector = (state) => state.authReducer.isLogin;

export default slice.reducer;

// Actions

const { loginSuccess, logoutSuccess, loginError, getProfileSuccess } = slice.actions;

export const login = (data) => async dispatch => {
    try {
        const response = await callApi(LOGIN_URL, 'POST', data);
        return dispatch(loginSuccess(response));
    } catch (e) {
        return dispatch(loginError(e.response.data.message.toLowerCase()));
    }
};

export const logout = () => async dispatch => {
    try {
        return dispatch(logoutSuccess());
    } catch (e) {
        return console.error(e.message);
    }
};

export const getProfile = () => async dispatch => {
    try {
        const response = await callAuthorizationApi(PROFILE_URL, 'GET');
        return dispatch(getProfileSuccess(response));
    } catch (e) {
        return console.error(e.message);
    }
};

export const getUploadAvarta = () => {
    return process.env.REACT_APP_API_URL + UPLOAD_TMP_FILE;
};

