import { createSlice } from '@reduxjs/toolkit';
import { SEARCH_BROKER_URL, UPDATE_STATUS_URL, UPDATE_APPROVE_URL, GET_LISTTAGS } from '../../constants/endpoint';
import { callApi, callAuthorizationApi } from "../../apis/axiosClient";



const slice = createSlice({
    name: 'broker',
    initialState: {
        data: [],
        isLoading: true,
        total: 0,
        perPage: 0,
        listtags: [],
    },
    reducers: {
        getBrokerSuccess: (state, action) => {
            state.data = action.payload.data.data;
            state.total = action.payload.data.total;
            state.per_page = action.payload.data.per_page;
            state.isLoading = false;
        },
        getBrokerError: (state, action) => {
            state.isLoading = false;
        },
        updateStatusBrokerSuccess: (state, action) => {
            const index = state.data.findIndex((data) => data._id === action.payload.id);
            state.data[index].status = action.payload.status;
            state.data = [...state.data];
            state.isLoading = false;
        },
        updateStatusBrokerError: (state, action) => {
            state.isLoading = false;
        },
        updateApproveBrokerSuccess: (state, action) => {
            const index = state.data.findIndex((data) => data._id === action.payload.id);
            state.data[index].approve_status = action.payload.approve_status;
            state.data = [...state.data];
            state.isLoading = false;
        },
        getApiListTagsBrokerSuccess: (state, action) => {
            state.listtags = action.payload.data;
            state.isLoading = false;
        },
        getApiListTagsBrokerError: (state, action) => {
            state.isLoading = false;
        },
    },
});

export default slice.reducer;

// Actions

const { getBrokerSuccess, getBrokerError, updateStatusBrokerSuccess, updateStatusBrokerError, getApiListTagsBrokerSuccess, getApiListTagsBrokerError, updateApproveBrokerSuccess } = slice.actions;

export const searchBroker = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(SEARCH_BROKER_URL, 'POST', params);
        return dispatch(getBrokerSuccess(response));
    } catch (e) {
        return dispatch(getBrokerError(e));
    }
};

export const onChangeBrokerStatus = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${UPDATE_STATUS_URL}/${params.id}`, 'PUT', { 'status': params.status });
        return dispatch(updateStatusBrokerSuccess(params));
    } catch (e) {
        return dispatch(updateStatusBrokerError(params));
    }
};

export const onChangeBrokerApprove = (params) => async dispatch => {
    try {
        const response = await callAuthorizationApi(`${UPDATE_APPROVE_URL}/${params.id}`, 'PUT', { 'status': params.approve_status });
        return dispatch(updateApproveBrokerSuccess(params));
    } catch (e) {
        return dispatch(updateStatusBrokerError(params));
    }
};

export const getApiBrokerListTags = () => async dispatch => {
    try {
        const response = await callAuthorizationApi(GET_LISTTAGS, 'GET', {});
        return dispatch(getApiListTagsBrokerSuccess(response));
    } catch (e) {
        return dispatch(getApiListTagsBrokerError());
    }
};
