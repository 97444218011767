import { DownOutlined } from "@ant-design/icons";
import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Layout, Menu, Dropdown, Badge } from "antd";
import { Link, useLocation, Route } from "react-router-dom";
import { HomeOutlined, ContactsFilled } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import './layout.scss';
import { logout } from "../../store/reducers/auth";
import createUser from '../../assets/images/img-menu/userlist.svg';
import listuseractive from '../../assets/images/img-menu/userlist-active.svg';
import home from '../../assets/images/img-menu/home.png';
const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
import { Permission } from "../../helpers/permission";
import { checkPermissions } from "../../helpers/role";
import { SETTING_MENU } from "../../constants/menu";
import { useHistory } from "react-router-dom";

const renderMenu = (menu) => {
  const { t } = useTranslation();
  if (menu.children && menu.children.length > 0) {
    return (
      <Menu mode="inline">
        <SubMenu key={menu.path} title={<span>{menu.name}</span>}>
          {menu.children.map((child) => {
            return renderMenu(child);
          })}
        </SubMenu>
      </Menu>
    );
  } else {
    return (
      <Menu.Item
        className={location.pathname === menu.path ? 'dashboard-menu-side-link dashboard-menu-side-link--active' : 'dashboard-menu-side-link dashboard-menu-side-link--inactve'}
        key={menu.path}
        icon={location.pathname === menu.path ? menu.icon1 : menu.icon}
      >
        <Link to={`${menu.path}`} className={location.pathname === menu.path ? null : "dashboard-menu__link"}>
          {t(`${menu.name}.title-menu`)}
        </Link>
      </Menu.Item>

    );
  }
};

const LayoutDashBoard = ({ children, menuData }) => {
  const { t } = useTranslation();
  const history  = useHistory();
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
    window.location = "/login";
  };

  const showInformation = () => {
    history.push('info');
  };
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  const MenuUser = (
    <Menu className="user-menu">
      <Menu.Item key="1" onClick={() => showInformation()} >{t("header.information")}</Menu.Item>
      <Menu.Item key="2" onClick={() => onLogout()}>
        {t("header.logout")}
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location]);

  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];


  const [openKeys, setOpenKeys] = React.useState(['sub1']);
  return (
    <Layout>
      <Header className="header">
        <div className="logo">Skyconnect</div>
        <div className="menu">
          <ul>
            {/* <li>
              <Badge count={5}>
                <a href="#" className="head-example" />
              </Badge>
            </li> */}
            <li>
              <Avatar className="avatar" src={user?.avatar} />
            </li>
            <li>
              <Dropdown className="user-info" overlay={MenuUser} width={400}>
                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  {user?.name} <DownOutlined />
                </a>
              </Dropdown>
            </li>
          </ul>
        </div>
      </Header>
      <Layout>
        <Sider breakpoint="lg" collapsedWidth="0" width="239" className="sidebar">
          {menuData && (
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              className="dashboard-menu"
              defaultOpenKeys={['sub1']}
            >

              <Menu.Item
                className="dashboard-menu-side-link"
                key='/'
                icon={location.pathname === '/' ? <HomeOutlined /> : <img src={home}></img>}
              >
                <Link className="dashboar-menu__home" to='/'>
                  {t('header.overview')}
                </Link>
              </Menu.Item>

              <SubMenu key="sub1" title={t('header.Menu-Items')} className='dashboard-menu__submenu'  >
                {menuData.map((menu) => {
                  if (checkPermissions(menu?.action)) {
                    return renderMenu(menu);

                  }
                  return "";
                })}
              </SubMenu>
              <SubMenu key="sub2" title={t('header.setting')} className='dashboard-menu__submenu'>
                {SETTING_MENU.map((menu) => {
                  if (checkPermissions(menu?.action)) {
                    return renderMenu(menu);

                  }
                  return "";
                })}
              </SubMenu>
            </Menu>
          )}
        </Sider>
        <Layout className='dashboard'>
          <Content className="dashboard-content">{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutDashBoard;
